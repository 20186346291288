import { updateObject } from '../Utility';
import { NEWS_GET_START, NEWS_GET_SUCCESS, NEWS_GET_FAIL,
         NEWS_ADD_COMMENT, NEWS_ADD_CHILD_COMMENT, NEWS_DELETE_COMMENT,
         ADD_NEWS_FROM_NOTIFICATION
} from '../actions/NewsActions'


const initialState = {
  items: [],
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getNewsStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getNewsSuccess = (state, action) => {
  return updateObject(state, {
    items: action.news,
    loading: false,
    fetched: true,
    error: null,
  });
}

const getNewsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


// ADD:
const addNewsFromNotification = (state, action) => {
  return updateObject(state, {
    ...state,
    items: [...state.items, action.news],
    loading: false,
    error: null,
  });
}

const addNewsComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map(news => news.id === action.comment.object_id ?
            // transform the one with a matching id
            { ...news, comments_count: news.comments_count + 1, comments: [...news.comments, action.comment] } :
            // otherwise return original todo
            news
          ),
    loading: false,
    error: null,
  });
}

const addNewsChildComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map(news => news.id === action.comment.object_id ?
            // transform the one with a matching id
            { ...news, comments_count: news.comments_count + 1, comments: news.comments.map(comment => comment.id === action.comment.base_parent?
              {...comment, children: [...comment.children, action.comment]}
              :
              comment
            )} :
            // otherwise return original news
            news
          ),
    loading: false,
    error: null,
  });
}

// DELETE:
const deleteNewsComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map(news => news.id === action.news_id? {...news, comments: news.comments.filter(comment => comment !== action.comment_id)} : news),
    loading: false,
    error: null,
  });
}


const NewsReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case NEWS_GET_START: return getNewsStart(state, action);
    case NEWS_GET_SUCCESS: return getNewsSuccess(state, action);
    case NEWS_GET_FAIL: return getNewsFail(state, action);

    // ADD:
    case NEWS_ADD_COMMENT: return addNewsComment(state, action);
    case NEWS_ADD_CHILD_COMMENT: return addNewsChildComment(state, action);
    case ADD_NEWS_FROM_NOTIFICATION: return addNewsFromNotification(state, action);

    // DELETE:
    case NEWS_DELETE_COMMENT: return deleteNewsComment(state, action);

    default:
      return state;
  }
}

export default NewsReducer;
