import { updateObject } from "../Utility";
import {
  PROFILE_GET_START,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAIL,
  PROFILE_UPDATE_START,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_USER_UPDATE_AVATAR,
  PROFILE_USER_UPDATE_NOTIFICATION_SETTINGS,
  PROFILE_ADD_RATING,
  PROFILE_UPDATE_RATING,
  PROFILE_ADD_VOTE,
  PROFILE_ADD_PROBLEM,
  PROFILE_ADD_PROBLEM_MESSAGE,
  PROFILE_ADD_SURVEY,
  RESUME_CREATE_START,
  RESUME_CREATE_SUCCESS,
  RESUME_CREATE_FAIL,
  RESUME_DELETE_START,
  RESUME_DELETE_SUCCESS,
  RESUME_DELETE_FAIL,
  RESUME_UPDATE_START,
  RESUME_UPDATE_SUCCESS,
  RESUME_UPDATE_FAIL,
  CUSTOM_RESUME_CREATE_START,
  CUSTOM_RESUME_CREATE_SUCCESS,
  CUSTOM_RESUME_CREATE_FAIL,
  CUSTOM_RESUME_DELETE_START,
  CUSTOM_RESUME_DELETE_SUCCESS,
  CUSTOM_RESUME_DELETE_FAIL,
  JOB_APPLICATION_APPLY_START,
  JOB_APPLICATION_APPLY_FAIL,
  WORKER_JOB_APPLICATION_CHANGE_STATUS_SUCCESS,
  NOTIFICATION_RECEIVED,
  NOTIFICATION_MARK_AS_READ_SUCCESS,
  USER_DOCUMENTS_UPDATE_START,
  USER_DOCUMENTS_UPDATE_SUCCESS,
  USER_DOCUMENTS_UPDATE_FAIL,
  NOTIFICATION_JOB_APPLICATION_UPDATE,
  USER_UPDATE_PROFILE_IMAGES_START,
  USER_UPDATE_PROFILE_IMAGES_SUCCESS,
  USER_UPDATE_PROFILE_IMAGES__FAIL,
  USER_UPDATE_COVER_IMAGE_START,
  USER_UPDATE_COVER_IMAGE_SUCCESS,
  USER_UPDATE_COVER_IMAGE_FAIL,
  CHECK_PHONE_VERIFY_FAIL,
  CHECK_PHONE_VERIFY_SUCCESS,
  CHECK_PHONE_VERIFY_START,
} from "../actions/ProfileActions";

const initialState = {
  data: {},
  error: null,
  updating: false,
  updated: false,
  loading: false,
  checking_phone_verified_status: false,
  fetched: false,
  profile_image: {
    data: null,
    uploading: false,
    error: null,
    status: null,
  },
  cover_image: {
    data: null,
    uploading: false,
    error: null,
    status: null,
  },
  resume: {
    loading: false,
    error: null,
  },
};

// GET:
const getProfileStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getProfileSuccess = (state, action) => {
  return updateObject(state, {
    fetched: true,
    data: action.data,
    error: null,
    loading: false,
  });
};

const getProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    fetched: false,
  });
};

const readNotificationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      notifications: state.data.notifications.map((notification) => {
        if (
          notification.id === action.notification.id ||
          (action.notification.uuid &&
            notification.uuid === action.notification.uuid)
        ) {
          return { ...notification, ...action.notification };
        } else {
          return notification;
        }
      }),
    },
  });
};

// CREATE:
const createResumeStart = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: true,
      error: null,
    },
  });
};

const createResumeSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      resume: action.resume,
    },
    resume: {
      loading: false,
      error: null,
    },
  });
};

const createResumeFail = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: false,
      error: action.error,
    },
  });
};

const createCustomResumeStart = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: true,
      error: null,
    },
  });
};

const createCustomResumeSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      resume_uploads: [...state.data.resume_uploads, action.resume],
    },
    resume: {
      loading: false,
      error: null,
    },
  });
};

const createCustomResumeFail = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: false,
      error: action.error,
    },
  });
};

const applyJobStart = (state, action) => {
  return updateObject(state, {
    ...state,
  });
};

const applyJobFail = (state, action) => {
  return updateObject(state, {
    ...state,
  });
};

// DELETE:
const deleteResumeStart = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: true,
      error: null,
    },
  });
};

const deleteResumeSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      resume: null,
    },
    resume: {
      loading: false,
      error: null,
    },
  });
};

const deleteResumeFail = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: false,
      error: action.error,
    },
  });
};

const deleteCustomResumeStart = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: true,
      error: null,
    },
  });
};

const deleteCustomResumeSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      resume_uploads: state.data.resume_uploads.filter(
        (resume) => resume.id !== action.id
      ),
    },
    resume: {
      loading: false,
      error: null,
    },
  });
};

const deleteCustomResumeFail = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: false,
      error: action.error,
    },
  });
};

// UPDATE:
const updateProfileStart = (state, actions) => {
  return updateObject(state, {
    error: null,
    updating: true,
  });
};

const updateProfileSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    error: null,
    updating: false,
    updated: true,
  });
};

const updateProfileFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    updating: false,
  });
};

const updateProfileUserAvatar = (state, action) => {
  return updateObject(state, {
    data: {
      ...state.data,
      avatar: action.avatar,
      logo: action.avatar,
    },
    error: null,
    loading: false,
  });
};

const updateProfileNotificationSettings = (state, action) => {
  return updateObject(state, {
    data: {
      ...state.data,
      firebase_enabled: action.notificationSettings.firebase_enabled,
    },
    error: null,
    loading: false,
  });
};

const updateResumeStart = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: true,
      error: null,
    },
  });
};

const updateResumeSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      resume: {
        ...state.data.resume,
        ...action.resume,
      },
    },
    resume: {
      loading: false,
      error: null,
    },
  });
};

const updateResumeFail = (state, action) => {
  return updateObject(state, {
    ...state,
    resume: {
      loading: false,
      error: action.error,
    },
  });
};

// INTERNAL
const addProfileRating = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      ratings: [...state.data.ratings, action.rating],
    },
  });
};

const addProfileSurvey = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      surveys: [...state.data.surveys, action.identifier],
    },
  });
};

const updateProfileRating = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      ratings: state.data.ratings.map((rating) => {
        if (rating.id === action.rating.id) {
          return action.rating;
        } else {
          return rating;
        }
      }),
    },
  });
};

const addProfileVote = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      voted_last_poll: action.vote,
    },
  });
};

// const addProfileProblem = (state, action) => {
//   return updateObject(state, {
//     ...state,
//     data: {
//       ...state.data,
//       problems: [action.problem, ...state.data.problems],
//     },
//   });
// };

const addProfileProblemMessage = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      problems: state.data.problems.map((problem) =>
        problem.id === action.message.problem_id
          ? {
              ...problem,
              messages: [...problem.messages, action.message],
              status: "WAITING_FOR_ISSARA",
              updated_at: new Date(),
            }
          : problem
      ),
    },
  });
};

const receiveNotification = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      notifications: [action.notification, ...state.data.notifications],
    },
  });
};

const notifyJobApplication = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      applications: state.data.applications.map((application) => {
        if (application.id === action.application.id) {
          return action.application;
        } else {
          return application;
        }
      }),
    },
  });
};

const workerJobApplicationChangeStatusSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      applications: state.data.applications.map((application) => {
        if (application.id === action.application.id) {
          return action.application;
        } else {
          return application;
        }
      }),
    },
  });
};

const updateUserDocumentsStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    updating: true,
    error: null,
  });
};

const updateUserDocumentsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    data: {
      ...state.data,
      ...action.documents,
    },
    loading: false,
    updating: false,
    error: null,
  });
};

const updateUserDocumentsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    updating: false,
    error: action.error,
  });
};

const updateUserProfileImagesStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    profile_image: {
      data: null,
      uploading: true,
      error: null,
      status: "Uploading ...",
    },
  });
};

const updateUserProfileImagesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    profile_image: {
      data: action.result,
      status: "Success",
      uploading: false,
      error: null,
    },
  });
};

const updateUserProfileImagesFail = (state, action) => {
  return updateObject(state, {
    ...state,
    profile_image: {
      data: null,
      uploading: false,
      status: "Failed to upload",
      error: action.error,
    },
  });
};

const updateUserCoverImageStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    cover_image: {
      data: null,
      uploading: true,
      error: null,
      status: "Uploading ...",
    },
  });
};

const updateUserCoverImageSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    cover_image: {
      data: action.result,
      status: "Success",
      uploading: false,
      error: null,
    },
  });
};

const updateUserCoverImageFail = (state, action) => {
  return updateObject(state, {
    ...state,
    cover_image: {
      data: null,
      uploading: false,
      status: "Failed to upload",
      error: action.error,
    },
  });
};

const checkPhoneVerifyStart = (state, action) => {
  return updateObject(state, {
    error: null,
    checking_phone_verified_status: true,
  });
};

const checkPhoneVerifySuccess = (state, action) => {
  return updateObject(state, {
    fetched: true,
    data: action.data,
    error: null,
    checking_phone_verified_status: false,
  });
};

const checkPhoneVerifyFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    fetched: false,
  });
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case PROFILE_GET_START:
      return getProfileStart(state, action);
    case PROFILE_GET_SUCCESS:
      return getProfileSuccess(state, action);
    case PROFILE_GET_FAIL:
      return getProfileFail(state, action);

    case NOTIFICATION_MARK_AS_READ_SUCCESS:
      return readNotificationSuccess(state, action);

    // CREATE:
    case RESUME_CREATE_START:
      return createResumeStart(state, action);
    case RESUME_CREATE_SUCCESS:
      return createResumeSuccess(state, action);
    case RESUME_CREATE_FAIL:
      return createResumeFail(state, action);

    case CUSTOM_RESUME_CREATE_START:
      return createCustomResumeStart(state, action);
    case CUSTOM_RESUME_CREATE_SUCCESS:
      return createCustomResumeSuccess(state, action);
    case CUSTOM_RESUME_CREATE_FAIL:
      return createCustomResumeFail(state, action);

    case JOB_APPLICATION_APPLY_START:
      return applyJobStart(state, action);
    case JOB_APPLICATION_APPLY_FAIL:
      return applyJobFail(state, action);

    // DELETE:
    case RESUME_DELETE_START:
      return deleteResumeStart(state, action);
    case RESUME_DELETE_SUCCESS:
      return deleteResumeSuccess(state, action);
    case RESUME_DELETE_FAIL:
      return deleteResumeFail(state, action);

    case CUSTOM_RESUME_DELETE_START:
      return deleteCustomResumeStart(state, action);
    case CUSTOM_RESUME_DELETE_SUCCESS:
      return deleteCustomResumeSuccess(state, action);
    case CUSTOM_RESUME_DELETE_FAIL:
      return deleteCustomResumeFail(state, action);

    // UPDATE:
    case PROFILE_UPDATE_START:
      return updateProfileStart(state, action);
    case PROFILE_UPDATE_SUCCESS:
      return updateProfileSuccess(state, action);
    case PROFILE_UPDATE_FAIL:
      return updateProfileFail(state, action);

    case RESUME_UPDATE_START:
      return updateResumeStart(state, action);
    case RESUME_UPDATE_SUCCESS:
      return updateResumeSuccess(state, action);
    case RESUME_UPDATE_FAIL:
      return updateResumeFail(state, action);

    case PROFILE_USER_UPDATE_AVATAR:
      return updateProfileUserAvatar(state, action);
    case PROFILE_USER_UPDATE_NOTIFICATION_SETTINGS:
      return updateProfileNotificationSettings(state, action);

    // INTERNAL
    case PROFILE_ADD_RATING:
      return addProfileRating(state, action);
    case PROFILE_UPDATE_RATING:
      return updateProfileRating(state, action);
    case PROFILE_ADD_VOTE:
      return addProfileVote(state, action);
    case PROFILE_ADD_PROBLEM_MESSAGE:
      return addProfileProblemMessage(state, action);
    case PROFILE_ADD_SURVEY:
      return addProfileSurvey(state, action);

    case NOTIFICATION_RECEIVED:
      return receiveNotification(state, action);
    case NOTIFICATION_JOB_APPLICATION_UPDATE:
      return notifyJobApplication(state, action);

    case WORKER_JOB_APPLICATION_CHANGE_STATUS_SUCCESS:
      return workerJobApplicationChangeStatusSuccess(state, action);

    case USER_DOCUMENTS_UPDATE_START:
      return updateUserDocumentsStart(state, action);
    case USER_DOCUMENTS_UPDATE_SUCCESS:
      return updateUserDocumentsSuccess(state, action);
    case USER_DOCUMENTS_UPDATE_FAIL:
      return updateUserDocumentsFail(state, action);

    case USER_UPDATE_PROFILE_IMAGES_START:
      return updateUserProfileImagesStart(state, action);
    case USER_UPDATE_PROFILE_IMAGES_SUCCESS:
      return updateUserProfileImagesSuccess(state, action);
    case USER_UPDATE_PROFILE_IMAGES__FAIL:
      return updateUserProfileImagesFail(state, action);

    case USER_UPDATE_COVER_IMAGE_START:
      return updateUserCoverImageStart(state, action);
    case USER_UPDATE_COVER_IMAGE_SUCCESS:
      return updateUserCoverImageSuccess(state, action);
    case USER_UPDATE_COVER_IMAGE_FAIL:
      return updateUserCoverImageFail(state, action);

    case CHECK_PHONE_VERIFY_START:
      return checkPhoneVerifyStart(state, action);
    case CHECK_PHONE_VERIFY_SUCCESS:
      return checkPhoneVerifySuccess(state, action);
    case CHECK_PHONE_VERIFY_FAIL:
      return checkPhoneVerifyFail(state, action);

    default:
      return state;
  }
};

export default ProfileReducer;
