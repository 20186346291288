// import moment from 'moment';
import burmese from 'moment/locale/my';
import burmeseZawgyi from './myZawgyi';
import khmer from 'moment/locale/km';
import english from "moment/locale/en-gb";
import thai from 'moment/locale/th';
import nepali from 'moment/locale/ne';


var moment = require('moment-timezone');


export const updateMomentLocale = (lang) => {
  
  // set English locale for developers
  if (process.env.REACT_APP_DISABLE_TRANSLATIIONS === 'true') {
    moment.tz.setDefault("Asia/Bangkok")
    moment.updateLocale('en', english)
    return
  }

  if (lang === 'mm-u') {
    moment.tz.setDefault("Asia/Yangon")
    moment.updateLocale('my', burmese)
  } else if (lang === 'mm-z') {
    moment.tz.setDefault("Asia/Yangon")
    moment.updateLocale('my-z', burmeseZawgyi)
  } else if (lang === 'kh') {
    moment.tz.setDefault("Asia/Phnom_Penh")
    moment.updateLocale('km', khmer)
  } else if (lang === 'th') {
    moment.tz.setDefault("Asia/Bangkok")
    moment.updateLocale('th', thai)
    document.body.style.fontFamily = 'Sarabun'
  } else if (lang === 'ne') {
    moment.tz.setDefault("Asia/Kathmandu");
    moment.updateLocale('ne', nepali);
  } else {
    moment.tz.setDefault("Asia/Bangkok")
    moment.updateLocale('en', english)
  }
}
