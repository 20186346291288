import { updateObject } from '../Utility';
import {
  RECRUITMENT_AGENCIES_GET_START, RECRUITMENT_AGENCIES_GET_SUCCESS,
  RECRUITMENT_AGENCIES_GET_FAIL, RECRUITMENT_AGENCIES_ADD_COMMENT,
  RECRUITMENT_AGENCIES_ADD_CHILD_COMMENT, RECRUITMENT_AGENCIES_RECALCULATE_SCORE,
  EXTENDED_RECRUITMENT_AGENCIES_GET_SUCCESS, RECRUITMENT_AGENCIES_VERIFY_NEW,
  RECRUITMENT_AGENCIES_REJECT_NEW,
  GET_RA_DETAIL_START, GET_RA_DETAIL_FAIL, GET_RA_DETAIL_SUCCESS,
  RECRUITMENT_AGENCY_ADD_COMMENT, RECRUITMENT_AGENCY_ADD_CHILD_COMMENT, RA_COMMENT_REMOVE
} from '../actions/RecruitmentAgenciesActions'


const initialState = {
  detail: {
    item: {},
    loading: false,
    fetched: false,
    error: false
  },
  items: [],
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getRecruitmentAgenciesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getRecruitmentAgenciesSuccess = (state, action) => {
  return updateObject(state, {
    items: action.recruitmentAgencies,
    loading: false,
    fetched: true,
    error: null,
  });
}

const getRecruitmentAgenciesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

const getExtendedRecruitmentAgenciesSuccess = (state, action) => {
  return updateObject(state, {
    items: action.extendedRecruitmentAgencies,
    loading: false,
    fetched: true,
    error: null,
  });
}

// INTERNAL:
const addRecruitmentAgenciesComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map(recruitmentAgency => recruitmentAgency.id === action.comment.object_id ?
      // transform the one with a matching id
      { ...recruitmentAgency, comments_count: recruitmentAgency.comments_count + 1, comments: [...recruitmentAgency.comments, action.comment] } :
      // otherwise return original recruitment agency
      recruitmentAgency
    ),
    loading: false,
    error: null,
  });
}

const addRecruitmentAgenciesChildComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map(recruitmentAgency => recruitmentAgency.id === action.comment.object_id ?
      // transform the one with a matching id
      {
        ...recruitmentAgency, comments_count: recruitmentAgency.comments_count + 1, comments: recruitmentAgency.comments.map(comment => comment.id === action.comment.base_parent ?
          { ...comment, children: [...comment.children, action.comment] }
          :
          comment
        )
      } :
      // otherwise return original recruitment agency
      recruitmentAgency
    ),
    loading: false,
    error: null,
  });
}

// One by One adding comment and reply
const addRecruitmentAgencyComment = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      ...state.detail,
      item: {
        ...state.detail.item,
        comments_count: state.detail.item.comments_count + 1,
        // comments: [
        //   ...state.detail.item.comments,
        //   action.comment
        // ]
        // I've comment out comments section because the response doesn't contain "comments" field
      }
    }
  });
}

// adding reply comments
const addRecruitmentAgencyChildComment = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      ...state.detail,
      item: {
        ...state.detail.item,
        comments_count: state.detail.item.comments_count + 1,
        // comments: state.detail.item.comments.map(comment => comment.id === action.comment.base_parent ?
        //   { ...comment, children: [...comment.children, action.comment] }
        //   :
        //   comment
        // )
      }
    }
  });
}

const recalculateRecruitmentAgencyScore = (state, action) => {
  return updateObject(state, {
    items: state.items.map(recruitmentAgency => recruitmentAgency.id === action.recruitment_agency_id ?
      // transform the one with a matching id
      {
        ...recruitmentAgency,
        rating_count: recruitmentAgency.rating_count + (action.update ? 0 : 1),
        rating_score_count: recruitmentAgency.rating_score_count + action.offsetCount,
        rating_score: (recruitmentAgency.rating_score * recruitmentAgency.rating_score_count + action.offsetScore) / (recruitmentAgency.rating_score_count + action.offsetCount),
        scores_for_each_criteria: recruitmentAgency.scores_for_each_criteria.map(item => {
          if (action.detailed_scores[item.rating_criteria_id]) {
            const newScore = action.detailed_scores[item.rating_criteria_id]
            if (newScore.updated) {
              return { ...item, score: (item.score * item.count + newScore.offset) / item.count }
            } else {
              return { ...item, score: (item.score * item.count + newScore.score) / (item.count + 1), count: item.count + 1 }
            }
          }
          else return item
        })
      }
      :
      // otherwise return original todo
      recruitmentAgency
    ),
    loading: false,
    error: null,
  });
}

const verifiyNewRecruitmentAgency = (state, action) => {
  return updateObject(state, {
    items: state.items.map(recruitmentAgency => recruitmentAgency.id === action.id ?
      // transform the one with a matching id
      { ...recruitmentAgency, managed_by: 'User' } :
      // otherwise return original recruitment agency
      recruitmentAgency
    )
  });
}

const rejectNewRecruitmentAgency = (state, action) => {
  return updateObject(state, {
    items: state.items.filter(recruitmentAgency => recruitmentAgency.id !== action.id)
  });
}

const mergeNewRecruitmentAgency = (state, action) => {
  return updateObject(state, {
    items: state.items.filter(recruitmentAgency => recruitmentAgency.id !== action.secondaryId)
  });
}

// GET RA Detail:
export const getRecruiterDetailStart = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: false,
      loading: true,
      error: false,
      item: {}
    }
  })
}

export const getRecruiterDetailSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: true,
      loading: false,
      error: false,
      item: action.recruiter
    }
  })
}

export const getRecruiterDetailFail = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: false,
      loading: false,
      error: action.error,
      item: {}
    }
  })
}

export const removeComment = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      ...state.detail,
      item: {
        ...state.detail.item,
        comments_count: state.detail.item.comments_count - 1,
        comments:  state.detail.item.comments.filter((item) => {
          return item.id !== action.comment.id || (item.id !== action.comment.id && item.base_parent == action.comment.id);
        })
      }
    }
  })
}



const RecruitmentAgenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case RECRUITMENT_AGENCIES_GET_START: return getRecruitmentAgenciesStart(state, action);
    case RECRUITMENT_AGENCIES_GET_SUCCESS: return getRecruitmentAgenciesSuccess(state, action);
    case RECRUITMENT_AGENCIES_GET_FAIL: return getRecruitmentAgenciesFail(state, action);

    case EXTENDED_RECRUITMENT_AGENCIES_GET_SUCCESS: return getExtendedRecruitmentAgenciesSuccess(state, action);

    // OTHER:
    case RECRUITMENT_AGENCIES_ADD_COMMENT: return addRecruitmentAgenciesComment(state, action);
    case RECRUITMENT_AGENCIES_ADD_CHILD_COMMENT: return addRecruitmentAgenciesChildComment(state, action);
    case RECRUITMENT_AGENCIES_RECALCULATE_SCORE: return recalculateRecruitmentAgencyScore(state, action)
    case RECRUITMENT_AGENCIES_VERIFY_NEW: return verifiyNewRecruitmentAgency(state, action)
    case RECRUITMENT_AGENCIES_REJECT_NEW: return rejectNewRecruitmentAgency(state, action)

    case GET_RA_DETAIL_START: return getRecruiterDetailStart(state, action);
    case GET_RA_DETAIL_FAIL: return getRecruiterDetailFail(state, action);
    case GET_RA_DETAIL_SUCCESS: return getRecruiterDetailSuccess(state, action);

    case RECRUITMENT_AGENCY_ADD_COMMENT: return addRecruitmentAgencyComment(state, action);
    case RECRUITMENT_AGENCY_ADD_CHILD_COMMENT: return addRecruitmentAgencyChildComment(state, action);

    // Comment
    case RA_COMMENT_REMOVE: return removeComment(state, action);

    default:
      return state;
  }
}

export default RecruitmentAgenciesReducer;
