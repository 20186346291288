import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const EDUCATION_LEVELS_GET_START = 'EDUCATION_LEVELS_GET_START';
export const EDUCATION_LEVELS_GET_SUCCESS = 'EDUCATION_LEVELS_GET_SUCCESS';
export const EDUCATION_LEVELS_GET_FAIL = 'EDUCATION_LEVELS_GET_FAIL';


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getEducationLevelsStart = () => {
  return {
    type: EDUCATION_LEVELS_GET_START,
  }
}

export const getEducationLevelsSuccess = (educationLevels) => {
  return {
    type: EDUCATION_LEVELS_GET_SUCCESS,
    educationLevels: educationLevels,
  }
}

export const getEducationLevelsFail = error => {
  return {
    type: EDUCATION_LEVELS_GET_FAIL,
    error: error
  }
}

export const getEducationLevels = () => {
  return (dispatch, getState) => {
    dispatch(getEducationLevelsStart());
    const language = getState().i18nState.lang
    return axios.get(`${process.env.REACT_APP_API_URL}/jobseekers/education-levels?lang=${language}`)
      .then(res => {
        dispatch(getEducationLevelsSuccess(res.data));
        return getState().EducationLevelsState.items
      })
      .catch(err => {
        dispatch(getEducationLevelsFail(err))
        throw err
      })
  }
}
