import { updateObject } from "../Utility";
import {
  RESUME_ROSTER_GET_START,
  RESUME_ROSTER_GET_SUCCESS,
  RESUME_ROSTER_GET_FAIL,
  RESUME_DETAIL_GET_START,
  RESUME_DETAIL_GET_SUCCESS,
  RESUME_DETAIL_GET_FAIL,
  RESUME_STATUS_PATCH_START,
  RESUME_STATUS_PATCH_SUCCESS,
  RESUME_STATUS_PATCH_FAIL,
  GET_GENERAL_WAITLIST_START,
  GET_GENERAL_WAITLIST_SUCCESS,
  GET_GENERAL_WAITLIST_FAIL,
} from "../actions/ResumeRosterActions";

const initialState = {
  items: {},
  itemsArray: [],
  generalWaitlist: {
    count: 0,
    fetched: false,
    fetching: false,
    itemsArray: [],
    error: false,
  },
  detail: {
    fetching: false,
    fetched: false,
    item: {},
    error: false,
  },
  status: {
    patching: false,
    patched: false,
    error: false,
    item: {},
  },
  fetching: false,
  fetched: false,
  error: null,
  // count: '',
  next: "",
  previous: "",
};

// GET:
const getResumeRosterStart = (state, action) => {
  return updateObject(state, {
    ...state,
    fetching: true,
    error: null,
    count: "",
    next: "",
    previous: "",
  });
};

const getResumeRosterSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    next: action.resumeRoster.next,
    previous: action.resumeRoster.previous,
    // count: action.resumeRoster.count,
    items: action.resumeRoster.results.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {}),
    itemsArray: action.resumeRoster.results,
    fetching: false,
    fetched: true,
    error: null,
  });
};

const getResumeRosterFail = (state, action) => {
  return updateObject(state, {
    ...state,
    fetching: false,
    error: action.error,
    count: "",
    next: "",
    previous: "",
  });
};

// GET RESUME DETAIL:
const getResumeDetailStart = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetching: true,
      error: false,
      fetched: false,
    },
  });
};

const getResumeDetailSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: true,
      fetching: false,
      error: false,
      item: action.resumeDetail,
    },
    status: {
      ...state.status,
      item: action.resumeDetail,
    },
  });
};

const getResumeDetailFail = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: false,
      fetching: false,
      error: action.error,
    },
  });
};

// PATCH RESUME STATUS:
const patchResumeStatusStart = (state, action) => {
  return updateObject(state, {
    ...state,
    status: {
      patching: true,
      error: false,
      patched: false,
    },
  });
};

const patchResumeStatusSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      ...state.detail,
      item: action.resumeDetail,
    },
    status: {
      patched: true,
      patching: false,
      error: false,
      item: action.resumeDetail,
    },
  });
};

const patchResumeStatusFail = (state, action) => {
  return updateObject(state, {
    ...state,
    status: {
      patched: false,
      patching: false,
      error: action.error,
    },
  });
};

// general watilist
const getGeneralWaitlistStart = (state, action) => {
  return updateObject(state, {
    ...state,
    generalWaitlist: {
      count: 0,
      fetched: false,
      fetching: true,
    },
  });
};

const getGeneralWaitlistSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    generalWaitlist: {
      count: action.waitlist.count,
      fetched: true,
      fetching: false,
      itemsArray: action.waitlist.results,
    },
  });
};

const getGeneralWaitlistFail = (state, action) => {
  return updateObject(state, {
    ...state,
    generalWaitlist: {
      count: 0,
      fetched: false,
      fetching: false,
      error: action.error,
    },
  });
};

const ResumeRosterReducer = (state = initialState, action) => {
  switch (action.type) {
    // RESUME ROSTER GET ACTIONS:
    case RESUME_ROSTER_GET_START:
      return getResumeRosterStart(state, action);
    case RESUME_ROSTER_GET_SUCCESS:
      return getResumeRosterSuccess(state, action);
    case RESUME_ROSTER_GET_FAIL:
      return getResumeRosterFail(state, action);

    // RESUME DETAIL GET ACTIONS:
    case RESUME_DETAIL_GET_START:
      return getResumeDetailStart(state, action);
    case RESUME_DETAIL_GET_SUCCESS:
      return getResumeDetailSuccess(state, action);
    case RESUME_DETAIL_GET_FAIL:
      return getResumeDetailFail(state, action);

    // RESUME STATUS PATCH ACTIONS:
    case RESUME_STATUS_PATCH_START:
      return patchResumeStatusStart(state, action);
    case RESUME_STATUS_PATCH_SUCCESS:
      return patchResumeStatusSuccess(state, action);
    case RESUME_STATUS_PATCH_FAIL:
      return patchResumeStatusFail(state, action);

    // GET GENERAL WAITLIST:
    case GET_GENERAL_WAITLIST_START:
      return getGeneralWaitlistStart(state, action);
    case GET_GENERAL_WAITLIST_SUCCESS:
      return getGeneralWaitlistSuccess(state, action);
    case GET_GENERAL_WAITLIST_FAIL:
      return getGeneralWaitlistFail(state, action);

    default:
      return state;
  }
};

export default ResumeRosterReducer;
