import { updateObject } from "../Utility";
import {
  AUTHENTICATION_SIGNUP_START,
  AUTHENTICATION_SIGNUP_SUCCESS,
  AUTHENTICATION_SIGNUP_FAIL,
  AUTHENTICATION_SIGNUP_CLEAR_ERRORS,
  AUTHENTICATION_LOGIN_START,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_LOGIN_FAIL,
  AUTHENTICATION_LOGIN_CLEAR_ERRORS,
  AUTHENTICATION_LOGOUT,
} from "../actions/AuthenticationActions";

const initialState = {
  token: null,
  loading: false,
  triedLogin: false,
  errors: {
    signup: null,
    login: null,
  },
};

// SIGNUP:
const authSignupStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    errors: {
      signup: null,
      login: null,
    },
  });
};

const authSignupSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    loading: false,
    errors: {
      signup: null,
      login: null,
    },
  });
};

const authSignupFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    errors: {
      signup: action.error,
    },
  });
};

const authSignupClearErrors = (state, action) => {
  return updateObject(state, {
    ...state,
    errors: {
      signup: null,
    },
  });
};

// LOGIN:
const authLoginStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    loading: true,
    errors: {
      signup: null,
      login: null,
    },
  });
};

const authLoginSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    loading: false,
    triedLogin: true,
    errors: {
      signup: null,
      login: null,
    },
  });
};

const authLoginFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    triedLogin: true,
    errors: {
      login: action.error,
    },
  });
};

const authLoginClearErrors = (state, action) => {
  return updateObject(state, {
    ...state,
    errors: {
      login: null,
    },
  });
};

// LOGOUT:
const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
  });
};

const AuthenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    // SIGNUP:
    case AUTHENTICATION_SIGNUP_START:
      return authSignupStart(state, action);
    case AUTHENTICATION_SIGNUP_SUCCESS:
      return authSignupSuccess(state, action);
    case AUTHENTICATION_SIGNUP_FAIL:
      return authSignupFail(state, action);
    case AUTHENTICATION_SIGNUP_CLEAR_ERRORS:
      return authSignupClearErrors(state, action);

    // LOGIN:
    case AUTHENTICATION_LOGIN_START:
      return authLoginStart(state, action);
    case AUTHENTICATION_LOGIN_SUCCESS:
      return authLoginSuccess(state, action);
    case AUTHENTICATION_LOGIN_FAIL:
      return authLoginFail(state, action);
    case AUTHENTICATION_LOGIN_CLEAR_ERRORS:
      return authLoginClearErrors(state, action);

    // LOGOUT:
    case AUTHENTICATION_LOGOUT:
      return authLogout(state, action);

    default:
      return state;
  }
};

export default AuthenticationReducer;
