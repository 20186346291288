import { updateObject } from '../Utility';
import {
  EXTEND_ROSTER_APPLICATION_START,
  EXTEND_ROSTER_APPLICATION_SUCCESS,
  EXTEND_ROSTER_APPLICATION_FAIL,
  WITHDRAW_ROSTER_APPLICATION_START,
  WITHDRAW_ROSTER_APPLICATION_SUCCESS,
  WITHDRAW_ROSTER_APPLICATION_FAIL
} from '../actions/WithdrawExtendActions'

const initialState = {
  withdraw: {
    error: null,
    withdrew: false,
    withdrawing: false,
    message: null
  },
  application: {
    fetched: false,
    extended: false,
    extending: false,
    error: null,
    message: null
  }
}

//EXTEND:
const extendRosterApplicationStart = (state, action) => {
  return updateObject(state, {
    ...state,
    application: {
      fetched: false,
      extended: false,
      extending: true,
      error: false
    }
  });
}

const extendRosterApplicationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    application: {
      fetched: true,
      item: action.application,
      extending: false,
      extended: true,
      error: false
    }
  });
}

const extendRosterApplicationFail = (state, action) => {
  return updateObject(state, {
    ...state,
    fetched: false,
    loading: false,
    error: action.error,
  });
}

// WITHDRAW:
const withdrawRosterApplicationStart = (state, action) => {
  return updateObject(state, {
    ...state,
    withdraw: {
      withdrawing: true,
      withdrew: false,
      error: false
    }
  });
}

const withdrawRosterApplicationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    withdraw: {
      message: action.message,
      withdrawing: false,
      withdrew: true,
      error: false
    }
  });
}

const withdrawRosterApplicationFail = (state, action) => {
  return updateObject(state, {
    ...state,
    withdraw: {
      withdrawing: false,
      withdrew: false,
      error: action.error
    }
  });
}


const WithdrawExtendReducer = (state = initialState, action) => {
  switch (action.type) {

    // EXTEND:
    case EXTEND_ROSTER_APPLICATION_START: return extendRosterApplicationStart(state, action);
    case EXTEND_ROSTER_APPLICATION_SUCCESS: return extendRosterApplicationSuccess(state, action);
    case EXTEND_ROSTER_APPLICATION_FAIL: return extendRosterApplicationFail(state, action)

    // WITHDRAW:
    case WITHDRAW_ROSTER_APPLICATION_START: withdrawRosterApplicationStart(state, action);
    case WITHDRAW_ROSTER_APPLICATION_SUCCESS: withdrawRosterApplicationSuccess(state, action);
    case WITHDRAW_ROSTER_APPLICATION_FAIL: withdrawRosterApplicationFail(state, action);

    default:
      return state;
  }
}

export default WithdrawExtendReducer;
