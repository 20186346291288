import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------

export const RECRUITER_ROSTER_GET_START = 'RECRUITER_ROSTER_GET_START';
export const RECRUITER_ROSTER_GET_SUCCESS = 'RECRUITER_ROSTER_GET_SUCCESS';
export const RECRUITER_ROSTER_GET_FAIL = 'RECRUITER_ROSTER_GET_FAIL';


// GET:
export const getRecruiterRosterStart = () => {
  return {
    type: RECRUITER_ROSTER_GET_START,
  }
}

export const getRecruiterRosterSuccess = (recruiterRoster) => {
  return {
    type: RECRUITER_ROSTER_GET_SUCCESS,
    recruiterRoster: recruiterRoster,
  }
}

export const getRecruiterRosterFail = error => {
  return {
    type: RECRUITER_ROSTER_GET_FAIL,
    error: error
  }
}

export const getRecruiterRoster = (roster) => {
  return (dispatch, getState) => {
    dispatch(getRecruiterRosterStart());
    const language = getState().i18nState.lang

    return axios.get(`${process.env.REACT_APP_API_URL}/recruitment-agencies?lang=${language}&roster=${roster}`)
      .then(res => {
        dispatch(getRecruiterRosterSuccess(res.data))
      })
      .catch(err => {
        dispatch(getRecruiterRosterFail(err))
        throw err
      })
  }
}

