import { omit } from 'lodash'
import { updateObject } from '../Utility';
import {
  COMMENTS_GET_START, COMMENTS_GET_SUCCESS, COMMENTS_GET_FAIL,
  COMMENT_POST_START, COMMENT_POST_SUCCESS, COMMENT_POST_FAIL,
  COMMENT_DELETE_START, COMMENT_DELETE_SUCCESS, COMMENT_DELETE_FAIL,
  COMMENT_ADD_REPLY, COMMENT_DELETE_REPLY,
  GOT_COMMENT_RESPONSE
} from '../actions/CommentsActions'


const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
  respondedComment: false,
}

// GET:
const getCommentsStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getCommentsSuccess = (state, action) => {
  return updateObject(state, {
    items: action.comments.reduce((obj, item) => {
      obj[item.id] = item
      return obj
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
}

const getCommentsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

// POST:
const postCommentStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const postCommentSuccess = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.comment.id]: action.comment,
    },
    loading: false,
    error: null,
  });
}

const postCommentFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

// DELETE:
const deleteCommentStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const deleteCommentSuccess = (state, action) => {
  return updateObject(state, {
    items: omit(state.items, action.comment_id),
    loading: false,
    error: null,
  });
}

const deleteCommentFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


// REPLIES:
const addCommentReply = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.parent_id]: {
        ...state.items[action.parent_id],
        replies: [
          ...state.items[action.parent_id].replies,
          action.comment_id
        ]
      },
    },
    loading: false,
    error: null,
  });
}

const gotCommentResponse = (state, action) => {
  return updateObject(state, {
    ...state,
    respondedComment: {...action.comment}
  });
}

const deleteCommentReply = (state, action) => {
  return updateObject(state, {
    items: {
      ...state.items,
      [action.parent_id]: {
        ...state.items[action.parent_id],
        replies: state.items[action.parent_id].replies.filter(reply => reply !== action.comment_id)
      }
    },
    loading: false,
    error: null,
  });
}


const CommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case COMMENTS_GET_START: return getCommentsStart(state, action);
    case COMMENTS_GET_SUCCESS: return getCommentsSuccess(state, action);
    case COMMENTS_GET_FAIL: return getCommentsFail(state, action);

    // POST:
    case COMMENT_POST_START: return postCommentStart(state, action);
    case COMMENT_POST_SUCCESS: return postCommentSuccess(state, action);
    case COMMENT_POST_FAIL: return postCommentFail(state, action);

    // DELETE:
    case COMMENT_DELETE_START: return deleteCommentStart(state, action);
    case COMMENT_DELETE_SUCCESS: return deleteCommentSuccess(state, action);
    case COMMENT_DELETE_FAIL: return deleteCommentFail(state, action);

    // REPLIES:
    case COMMENT_ADD_REPLY: return addCommentReply(state, action);
    case COMMENT_DELETE_REPLY: return deleteCommentReply(state, action);

    case GOT_COMMENT_RESPONSE: return gotCommentResponse(state, action);


    default:
      return state;
  }
}

export default CommentsReducer;
