import { updateObject } from '../Utility';
import { MARITAL_STATUSES_GET_START, MARITAL_STATUSES_GET_SUCCESS,
         MARITAL_STATUSES_GET_FAIL } from '../actions/MaritalStatusesActions'


const initialState = {
  items: {},
  itemsArray: [],
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getMaritalStatusesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getMaritalStatusesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.maritalStatuses.reduce((obj, item) => {
       obj[item.marital_status] = item
       return obj
     }, {}),
    itemsArray: action.maritalStatuses,
    loading: false,
    fetched: true,
    error: null,
  });
}

const getMaritalStatusesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


const MaritalStatusesReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case MARITAL_STATUSES_GET_START: return getMaritalStatusesStart(state, action);
    case MARITAL_STATUSES_GET_SUCCESS: return getMaritalStatusesSuccess(state, action);
    case MARITAL_STATUSES_GET_FAIL: return getMaritalStatusesFail(state, action);

    default:
      return state;
  }
}

export default MaritalStatusesReducer;
