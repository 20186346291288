import { updateObject } from "../Utility";
import {
  NOTIFICATIONS_GET_SUCCESS,
  NOTIFICATIONS_GET_START,
  NOTIFICATIONS_GET_FAIL,
} from "../actions/NotificationsActions";

const initialState = {
  items: [],
  loading: false,
  fetched: false,
  error: null,
};

const getNotificationsStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.notifications,
    loading: false,
    fetched: true,
    error: null,
  });
};

const getNotificationsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_GET_START:
      return getNotificationsStart(state, action);
    case NOTIFICATIONS_GET_SUCCESS:
      return getNotificationsSuccess(state, action);
    case NOTIFICATIONS_GET_FAIL:
      return getNotificationsFail(state, action);

    default:
      return state;
  }
};

export default NotificationsReducer;
