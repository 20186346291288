// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// Imoprt third-party modules
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Result, Button } from 'antd';

import { getStaticPages } from '../../../common/redux/actions/StaticPagesActions';

// Import styles
import styles from './index.module.css';


export default function Disclaimer(props, context) {
  const history = useHistory()
  const dispatch = useDispatch()

  const staticPages = useSelector(state => state.StaticPagesState)
  const [disclaimer, setDisclaimer] = useState(null)

  useEffect(() => {
    !staticPages.fetched && !staticPages.loading && dispatch(getStaticPages())
  }, []);

  useEffect(() => {
    if (staticPages.fetched) {
      let disclaimer = staticPages.items['disclaimer_business']
      disclaimer? setDisclaimer(disclaimer) : history.push('/404')
    }
  }, [staticPages.items]);

  return (
    <div className={styles.container}>
      {disclaimer?
        <div className={styles.content} dangerouslySetInnerHTML={{__html: disclaimer.content}}/>
        :
        <Spin tip={context.t('Loading..')}/>
      }
    </div>
  )
}

Disclaimer.contextTypes = {
  t: PropTypes.func.isRequired
}
