import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const COMMENT_FLAG_POST_START = 'COMMENT_FLAG_POST_START';
export const COMMENT_FLAG_POST_SUCCESS = 'COMMENT_FLAG_POST_SUCCESS';
export const COMMENT_FLAG_POST_FAIL = 'COMMENT_FLAG_POST_FAIL';

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// POST:
export const postCommentFlagStart = () => {
  return {
    type: COMMENT_FLAG_POST_START,
  }
}

export const postCommentFlagSuccess = () => {
  return {
    type: COMMENT_FLAG_POST_SUCCESS,
  }
}

export const postCommentFlagFail = error => {
  return {
    type: COMMENT_FLAG_POST_FAIL,
    error: error
  }
}

export const postCommentFlag = (payload) => {
  return dispatch => {
    console.log(payload)
      dispatch(postCommentFlagStart());

      axios.post(`${process.env.REACT_APP_API_URL}/comment-flags`, payload)
      .then(res => {
          dispatch(postCommentFlagSuccess(res.data));
      })
      .catch(err => {
          dispatch(postCommentFlagFail(err))
      })
  }
}
