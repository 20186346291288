//! moment.js locale configuration
//! locale : Burmese Zawgyi [my-z]


import moment from 'moment';

var symbolMap = {
        '1': '၁',
        '2': '၂',
        '3': '၃',
        '4': '၄',
        '5': '၅',
        '6': '၆',
        '7': '၇',
        '8': '၈',
        '9': '၉',
        '0': '၀',
    },
    numberMap = {
        '၁': '1',
        '၂': '2',
        '၃': '3',
        '၄': '4',
        '၅': '5',
        '၆': '6',
        '၇': '7',
        '၈': '8',
        '၉': '9',
        '၀': '0',
    };

export default moment.defineLocale('my-z', {
    months: 'ဇန္နဝါရီ_ေဖေဖာ္ဝါရီ_မတ္_ဧၿပီ_ေမ_ဇြန္_ဇူလိုင္_ၾသဂုတ္_စက္တင္ဘာ_ေအာက္တိုဘာ_နိုဝင္ဘာ_ဒီဇင္ဘာ'.split(
        '_'
    ),
    monthsShort: 'ဇန္_ေဖ_မတ္_ၿပီ_ေမ_ဇြန္_လိုင္_ၾသ_စက္_ေအာက္_နို_ဒီ'.split('_'),
    weekdays: 'တနဂၤေႏြ_တနလၤာ_အဂၤါ_ဗုဒၶဟူး_ၾကာသပေတး_ေသာၾကာ_စေန'.split(
        '_'
    ),
    weekdaysShort: 'ေႏြ_လာ_ဂါ_ဟူး_ၾကာ_ေသာ_ေန'.split('_'),
    weekdaysMin: 'ေႏြ_လာ_ဂါ_ဟူး_ၾကာ_ေသာ_ေန'.split('_'),

    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
        sameDay: '[ယေန.] LT [မွာ]',
        nextDay: '[မနက္ျဖန္] LT [မွာ]',
        nextWeek: 'dddd LT [မွာ]',
        lastDay: '[မေန.က] LT [မွာ]',
        lastWeek: '[ၿပီးခဲ့ေသာ] dddd LT [မွာ]',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'လာမည့္ %s မွာ',
        past: 'လြန္ခဲ့ေသာ %s က',
        s: 'စကၠန္.အနည္းငယ္',
        ss: '%d စကၠန႔္',
        m: 'တစ္မိနစ္',
        mm: '%d မိနစ္',
        h: 'တစ္နာရီ',
        hh: '%d နာရီ',
        d: 'တစ္ရက္',
        dd: '%d ရက္',
        M: 'တစ္လ',
        MM: '%d လ',
        y: 'တစ္ႏွစ္',
        yy: '%d ႏွစ္',
    },
    preparse: function (string) {
        return string.replace(/[၁၂၃၄၅၆၇၈၉၀]/g, function (match) {
            return numberMap[match];
        });
    },
    postformat: function (string) {
        return string.replace(/\d/g, function (match) {
            return symbolMap[match];
        });
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});
