import { updateObject } from '../Utility';
import {
  JOBS_GET_START, JOBS_GET_SUCCESS, JOBS_GET_FAIL,
  FETCH_JOB_FROM_NOTIFICATION_START,
  FETCH_JOB_FROM_NOTIFICATION_SUCCESS,
  FETCH_JOB_FROM_NOTIFICATION_FAIL
} from '../actions/JobsActions'


const initialState = {
  items: [],
  fetching: false,
  fetched: false,
  error: null,
}

const fetchJobFromNotificationStart = (state, action) => {
  return updateObject(state, {
    ...state,
    fetching: true,
  });
}

const fetchJobFromNotificationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: [...state.items, action.job],
    fetching: false
  });
}

const fetchJobFromNotificationFail = (state, action) => {
  return updateObject(state, {
    ...state,
    fetching: false,
  });
}

// GET:
const getJobsStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    fetching: true,
    error: null,
  });
}

const getJobsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.jobs,
    fetching: false,
    fetched: true,
    error: null,
  });
}

const getJobsFail = (state, action) => {
  return updateObject(state, {
    ...state,
    fetching: false,
    error: action.error,
  });
}

const JobsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case JOBS_GET_START: return getJobsStart(state, action);
    case JOBS_GET_SUCCESS: return getJobsSuccess(state, action);
    case JOBS_GET_FAIL: return getJobsFail(state, action);

    // ADD:
    case FETCH_JOB_FROM_NOTIFICATION_START: return fetchJobFromNotificationStart(state, action);
    case FETCH_JOB_FROM_NOTIFICATION_SUCCESS: return fetchJobFromNotificationSuccess(state, action);
    case FETCH_JOB_FROM_NOTIFICATION_FAIL: return fetchJobFromNotificationFail(state, action);

    default:
      return state;
  }
}

export default JobsReducer;
