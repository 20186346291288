import axios from 'axios';

// zawgyi-unicode converter
const Rabbit = require("rabbit-node");


// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const GUIDES_GET_START = 'GUIDES_GET_START';
export const GUIDES_GET_SUCCESS = 'GUIDES_GET_SUCCESS';
export const GUIDES_GET_FAIL = 'GUIDES_GET_FAIL';

export const GUIDES_ADD_COMMENT = 'GUIDES_ADD_COMMENT';
export const GUIDES_ADD_CHILD_COMMENT = 'GUIDES_ADD_CHILD_COMMENT';
export const GUIDES_DELETE_COMMENT = 'GUIDES_DELETE_COMMENT';
// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getGuidesStart = () => {
  return {
    type: GUIDES_GET_START,
  }
}

export const getGuidesSuccess = (guides) => {
  return {
    type: GUIDES_GET_SUCCESS,
    guides: guides,
  }
}

export const getGuidesFail = error => {
  return {
    type: GUIDES_GET_FAIL,
    error: error
  }
}

export const getGuides = () => {
  return (dispatch, getState) => {
      dispatch(getGuidesStart());

      const language = getState().i18nState.lang

      axios.get(`${process.env.REACT_APP_API_URL}/guides?lang=${language}`)
      .then(res => {
        let guides = res.data

        // add unicode names to be used in the search later
        if (language === 'mm-z') {
          guides = guides.map(guide => {
            return {...guide, title_mm_uni: Rabbit.zg2uni(guide.title)}
          })
        }

        dispatch(getGuidesSuccess(guides));
        return getState().GuidesState.items
      })
      .catch(err => {
        dispatch(getGuidesFail(err))
        throw err
      })
  }
}

// ADD:
export const addGuidesComment = (comment) => {
  return {
    type: GUIDES_ADD_COMMENT,
    comment: comment,
  }
}

export const addGuidesChildComment = (comment) => {
  return {
    type: GUIDES_ADD_CHILD_COMMENT,
    comment: comment,
  }
}

// DELETE:
export const deleteGuidesComment = (guide_id, comment_id) => {
  console.log(`RECEIVED GUIDE ID: ${guide_id}, COMMENT ID: ${comment_id}`)
  return {
    type: GUIDES_DELETE_COMMENT,
    guide_id: guide_id,
    comment_id: comment_id
  }
}
