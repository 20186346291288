import axios from 'axios';

import { addProfileProblem, addProfileProblemMessage } from './ProfileActions';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const PROBLEMS_GET_START = 'PROBLEMS_GET_START';
export const PROBLEMS_GET_SUCCESS = 'PROBLEMS_GET_SUCCESS';
export const PROBLEMS_GET_FAIL = 'PROBLEMS_GET_FAIL';

export const PROBLEMS_POST_PROBLEM_START = 'PROBLEMS_POST_PROBLEM_START';
export const PROBLEMS_POST_PROBLEM_SUCCESS = 'PROBLEMS_POST_PROBLEM_SUCCESS';
export const PROBLEMS_POST_PROBLEM_FAIL = 'PROBLEMS_POST_PROBLEM_FAIL';

export const PROBLEMS_RESOLVE_PROBLEM_START = 'PROBLEMS_RESOLVE_PROBLEM_START';
export const PROBLEMS_RESOLVE_PROBLEM_SUCCESS = 'PROBLEMS_RESOLVE_PROBLEM_SUCCESS';
export const PROBLEMS_RESOLVE_PROBLEM_FAIL = 'PROBLEMS_RESOLVE_PROBLEM_FAIL';

export const PROBLEMS_POST_MESSAGE_START = 'PROBLEMS_POST_MESSAGE_START';
export const PROBLEMS_POST_MESSAGE_SUCCESS = 'PROBLEMS_POST_MESSAGE_SUCCESS';
export const PROBLEMS_POST_MESSAGE_FAIL = 'PROBLEMS_POST_MESSAGE_FAIL';

// POST:
export const postProblemStart = () => {
  return {
    type: PROBLEMS_POST_PROBLEM_START,
  }
}

export const postProblemSuccess = (problem) => {
  return {
    type: PROBLEMS_POST_PROBLEM_SUCCESS,
  }
}

export const postProblemFail = error => {
  return {
    type: PROBLEMS_POST_PROBLEM_FAIL,
  }
}

export const postProblem = (payload) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(postProblemStart());
      axios.post(`${process.env.REACT_APP_API_URL}/problems/new-problem`, payload)
      .then(res => {
        dispatch(postProblemSuccess(res.data));
        dispatch(addProfileProblem(res.data));
        resolve(res.data)
      })
      .catch(err => {
        dispatch(postProblemFail(err))
        reject(err)
      })
    })
  }
}

export const postMessageStart = () => {
  return {
    type: PROBLEMS_POST_MESSAGE_START,
  }
}

export const postMessageSuccess = message => {
  return {
    type: PROBLEMS_POST_MESSAGE_SUCCESS,
    message: message
  }
}

export const postMessageFail = error => {
  return {
    type: PROBLEMS_POST_MESSAGE_FAIL,
  }
}

export const postMessage = (payload) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_API_URL}/problems/new-message`, payload)
      .then(res => {
        if (res.data.user_type === 'IS') {
          dispatch(postMessageSuccess(res.data));
        } else if (res.data.user_type === 'JS') {
          dispatch(addProfileProblemMessage(res.data));
        }
        resolve(res.data)
      })
      .catch(err => {
        dispatch(postMessageFail(err))
        reject(err)
      })
    })
  }
}

// PATCH
export const resolveProblemStart = () => {
  return {
    type: PROBLEMS_RESOLVE_PROBLEM_START,
  }
}

export const resolveProblemSuccess = (problem) => {
  return {
    type: PROBLEMS_RESOLVE_PROBLEM_SUCCESS,
    problem: problem
  }
}

export const resolveProblemFail = error => {
  return {
    type: PROBLEMS_RESOLVE_PROBLEM_FAIL,
  }
}

export const resolveProblem = (problemId) => {
  return dispatch => {
    const payload = {status: 'RESOLVED'}
    return new Promise((resolve, reject) => {
      dispatch(resolveProblemStart());
      axios.patch(`${process.env.REACT_APP_API_URL}/problems/${problemId}`, payload)
      .then(res => {
        dispatch(resolveProblemSuccess(res.data));
        resolve(res.data)
      })
      .catch(err => {
        dispatch(resolveProblemFail(err))
        reject(err)
      })
    })
  }
}

// GET
export const getProblemsStart = () => {
  return {
    type: PROBLEMS_GET_START,
  }
}

export const getProblemsSuccess = (problems) => {
  return {
    type: PROBLEMS_GET_SUCCESS,
    problems: problems,
  }
}

export const getProblemsFail = error => {
  return {
    type: PROBLEMS_GET_FAIL,
    error: error
  }
}

export const getProblems = () => {
  return (dispatch, getState) => {
    dispatch(getProblemsStart());
    axios.get(`${process.env.REACT_APP_API_URL}/problems`)
    .then(res => {
      let problems = res.data
      dispatch(getProblemsSuccess(problems));
      return getState().ProblemsState.items
    })
    .catch(err => {
      dispatch(getProblemsFail(err))
      throw err
    })
  }
}
