import { updateObject } from '../Utility';
import { COUNTRIES_GET_START, COUNTRIES_GET_SUCCESS,
         COUNTRIES_GET_FAIL } from '../actions/CountriesActions'


const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getCountriesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getCountriesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.countries.reduce((obj, item) => {
       obj[item.country] = item
       return obj
     }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
}

const getCountriesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


const CountriesReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case COUNTRIES_GET_START: return getCountriesStart(state, action);
    case COUNTRIES_GET_SUCCESS: return getCountriesSuccess(state, action);
    case COUNTRIES_GET_FAIL: return getCountriesFail(state, action);

    default:
      return state;
  }
}

export default CountriesReducer;
