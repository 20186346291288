import { updateObject } from '../Utility';
import {
  PENDING_EMP_START,
  PENDING_EMP_FAIL, PENDING_EMP_SUCCESS,
} from '../actions/EmployersActions'


const initialState = {
  next: null,
  previous: null,
  count: 0,
  page_size: 0,
  items: [],
  loading: false,
  fetched: false,
  error: null,
}

// pending EMP
export const getPendingEmpSuccess = (state, action) => {
  return updateObject(state, {
    next: action.emp.next,
    previous: action.emp.previous,
    count: action.emp.count,
    page_size: action.emp.page_size,
    items: action.emp.results,
    loading: false,
    fetched: true,
    error: null,
  });
}

export const getPendingEmpFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

export const getPendingEmpStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const PendingEmployerReducer = (state = initialState, action) => {
  switch (action.type) {

    case PENDING_EMP_START: return getPendingEmpStart(state, action);
    case PENDING_EMP_SUCCESS: return getPendingEmpSuccess(state, action);
    case PENDING_EMP_FAIL: return getPendingEmpFail(state, action)

    default:
      return state;
  }
}

export default PendingEmployerReducer;
