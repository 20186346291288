import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const SUB_INDUSTRIES_GET_START = 'SUB_INDUSTRIES_GET_START';
export const SUB_INDUSTRIES_GET_SUCCESS = 'SUB_INDUSTRIES_GET_SUCCESS';
export const SUB_INDUSTRIES_GET_FAIL = 'SUB_INDUSTRIES_GET_FAIL';


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getSubIndustriesStart = () => {
  return {
    type: SUB_INDUSTRIES_GET_START,
  }
}

export const getSubIndustriesSuccess = (subIndustries) => {
  return {
    type: SUB_INDUSTRIES_GET_SUCCESS,
    SubIndustries: subIndustries,
  }
}

export const getSubIndustriesFail = error => {
  return {
    type: SUB_INDUSTRIES_GET_FAIL,
    error: error
  }
}

export const getSubIndustries = () => {
  console.log('start')
  return (dispatch, getState) => {
      dispatch(getSubIndustriesStart());
      const language = getState().i18nState.lang
      return axios.get(`${process.env.REACT_APP_API_URL}/sub-industries?lang=${language}`)
      .then(res => {
          dispatch(getSubIndustriesSuccess(res.data));
          return getState().SubIndustriesState.items
      })
      .catch(err => {
          dispatch(getSubIndustriesFail(err))
          throw err
      })
  }
}
