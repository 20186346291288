import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const COUNTRIES_GET_START = 'COUNTRIES_GET_START';
export const COUNTRIES_GET_SUCCESS = 'COUNTRIES_GET_SUCCESS';
export const COUNTRIES_GET_FAIL = 'COUNTRIES_GET_FAIL';


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getCountriesStart = () => {
  return {
    type: COUNTRIES_GET_START,
  }
}

export const getCountriesSuccess = (countries) => {
  return {
    type: COUNTRIES_GET_SUCCESS,
    countries: countries,
  }
}

export const getCountriesFail = error => {
  return {
    type: COUNTRIES_GET_FAIL,
    error: error
  }
}

export const getCountries = () => {
  return (dispatch, getState) => {
    dispatch(getCountriesStart());
    const language = getState().i18nState.lang
    return axios.get(`${process.env.REACT_APP_API_URL}/localization/countries?lang=${language}`)
      .then(res => {
        dispatch(getCountriesSuccess(res.data));
        return getState().CountriesState.items
      })
      .catch(err => {
        dispatch(getCountriesFail(err))
        throw err
      })
  }
}
