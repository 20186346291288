import { COMMENT_Reaction_SUCCESS , COMMENT_Reaction_PUT_SUCCESS , COMMENT_Reaction_POST_SUCCESS} from "../actions/CommentReactionAction"

const intialState = {
  items: null,
  updatedata:null,
  postdata:null
};
const CommentReactionReducer = (state = intialState, action) => {
  switch (action.type) {
    case COMMENT_Reaction_SUCCESS:
      return {
        ...state,
        items: action.payload,
      }
    case COMMENT_Reaction_PUT_SUCCESS :
      return{
        ...state,
        updatedata : action.payload,
      }
      case COMMENT_Reaction_POST_SUCCESS :
        return{
          ...state,
          postdata : action.payload,
        }
    default:
      return state
  }
}

export default CommentReactionReducer;