import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const STATIC_PAGES_GET_START = 'STATIC_PAGES_GET_START';
export const STATIC_PAGES_GET_SUCCESS = 'STATIC_PAGES_GET_SUCCESS';
export const STATIC_PAGES_GET_FAIL = 'STATIC_PAGES_GET_FAIL';

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getStaticPagesStart = () => {
  return {
    type: STATIC_PAGES_GET_START,
  }
}

export const getStaticPagesSuccess = (staticPages) => {
  return {
    type: STATIC_PAGES_GET_SUCCESS,
    staticPages: staticPages,
  }
}

export const getStaticPagesFail = error => {
  return {
    type: STATIC_PAGES_GET_FAIL,
    error: error
  }
}

export const getStaticPages = () => {
  return (dispatch, getState) => {
      dispatch(getStaticPagesStart());
      const language = getState().i18nState.lang
      return axios.get(`${process.env.REACT_APP_API_URL}/static-pages?lang=${language}`)
      .then(res => {
          dispatch(getStaticPagesSuccess(res.data));
          return getState().StaticPagesState.items
      })
      .catch(err => {
          dispatch(getStaticPagesFail(err))
          throw err
      })
  }
}
