// Import built-in modules
import React, { useEffect, Suspense } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Spin, Modal, notification as antNotification } from "antd";
import { setLanguage } from "redux-i18n";

// Import goldendreams modules
import EmployerApplicationsDetailedView from "./containers/ConsoleEmployer/Applications/EmployerApplicationsDetailedView";
import RecruiterApplicationDetailedView from "./containers/ConsoleRecruiter/applications/RecruiterApplicationDetailedView";

import Disclaimer from "./containers/pages/Disclaimer";
import LandingView from "./containers/landing/LandingView";
import LoginView from "./containers/login/LoginView";
import RegistrationView from "./containers/registration/RegistrationView";
import EmailVerificationView from "./containers/verification/EmailVerificationView";
import PasswordResetView from "./containers/passwordReset/PasswordResetView";
import PrivacyPolicy from "./containers/pages/PrivacyPolicy";
import UnathorizedView from "./containers/pages/403/UnathorizedView";
import NotFoundView from "./containers/pages/404/NotFoundView";
import DecideRedirection from "./containers/NotValidUser/DecideRedirection";

import {
  authAutoLogin,
  clearAuthLocalStorage,
} from "./common/redux/actions/AuthenticationActions";
import { jobApplicationStatusChangeNotification } from "./common/redux/actions/MyJobsActions";
import { getLanguages } from "./common/redux/actions/LanguagesActions";
import { receiveNotification } from "./common/redux/actions/ProfileActions";
import { updateMomentLocale } from "./common/i18n/utilities";

// Import styles
import "antd/dist/antd.css";
import "./index.css";

// Code splitting to reduce build chunks size
const ConsoleEmployerLayout = React.lazy(() =>
  import("./containers/layouts/ConsoleEmployerLayout/ConsoleEmployerLayout")
);
const ConsoleIssaraLayout = React.lazy(() =>
  import("./containers/layouts/ConsoleIssaraLayout/ConsoleIssaraLayout")
);
const LandingLayout = React.lazy(() =>
  import("./containers/layouts/landing/LandingLayout")
);
const ConsoleRecruiterLayout = React.lazy(() =>
  import("./containers/layouts/ConsoleRecruiterLayout/ConsoleRecruiterLayout")
);

const AuthenticatedRoute = ({
  component: Component,
  layout: Layout,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

const UnauthenticatedRoute = ({
  component: Component,
  layout: Layout,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }
  />
);

const StandartRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const IssaraRoute = ({
  component: Component,
  layout: Layout,
  isAuthenticated,
  isIssara,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        isIssara ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/403" />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default function App(props, context) {
  const history = useHistory();
  const dispatch = useDispatch();

  const authentication = useSelector((state) => state.AuthenticationState);
  const myJobs = useSelector((state) => state.MyJobsState);
  const languages = useSelector((state) => state.LanguagesState);
  const profile = useSelector((state) => state.ProfileState);
  const serviceWorker = useSelector((state) => state.ServiceWorkerState);

  const updateNotificationRelatedObject = async (notificationType, data) => {
    if (
      [
        "APPLICATION_SUBMITTED",
        "APPLICATION_ACCEPTED",
        "APPLICATION_REJECTED_BY_APPLICANT",
      ].includes(notificationType) &&
      myJobs.fetched
    ) {
      const applicationID = JSON.parse(data.application);

      // can't pass whole application from the server, Firebase message has a limit,
      // so only ID is passed, then application is fetched on the client
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/business/job-applications/${applicationID}/`
        )
        .then((res) =>
          dispatch(jobApplicationStatusChangeNotification(res.data))
        );
    }
  };

  useEffect(() => {
    if (serviceWorker.updated) {
      Modal.info({
        title: context.t("Update is available"),
        content: context.t("Please press OK to refresh the app."),
        onOk: () => window.location.reload(),
        centered: true,
      });
    }
  }, [serviceWorker]);

  // add firebase notifications listener
  useEffect(() => {
    const handleForegroundFirebaseMessage = async (payload) => {
      let message = payload.data["firebase-messaging-msg-data"].data;
      let notification = JSON.parse(message.notification);

      updateNotificationRelatedObject(notification.type, message);

      profile.data.id && dispatch(receiveNotification(notification));

      antNotification.open({
        message: notification.message_title,
        description: notification.message_body,
        placement: "bottomLeft",
        duration: 5,
        onClick: () => history.push(notification.message_click_action),
      });
    };

    navigator.serviceWorker &&
      navigator.serviceWorker.addEventListener(
        "message",
        handleForegroundFirebaseMessage
      );

    return () =>
      navigator.serviceWorker &&
      navigator.serviceWorker.removeEventListener(
        "message",
        handleForegroundFirebaseMessage
      );
    // re-register listener on state change
  }, [myJobs, profile]);

  useEffect(() => {
    // fetch languages
    !languages.fetched && !languages.loading && dispatch(getLanguages());

    // set language in i18n redux from local storage
    const language = localStorage.getItem("language");
    dispatch(setLanguage(language || "en"));
    updateMomentLocale(language || "en");

    // try to automatically login using token stored in locale storage
    dispatch(authAutoLogin())
      .then((res) => {
        console.log("Success login");
      })
      .catch((e) => {
        if (e.response && [400, 401, 404].includes(e.response.status)) {
          clearAuthLocalStorage();
          window.location.reload();
        }
      });
  }, []);

  return authentication.triedLogin && !profile.loading && !languages.loading ? (
    <Suspense
      fallback={
        <div style={{ height: "100vh" }}>
          <div className="spinWrapper">
            <Spin tip={"Loading route.."} />
          </div>
        </div>
      }
    >
      <Switch>
        <IssaraRoute
          path="/console-issara"
          layout={ConsoleIssaraLayout}
          component={""}
          isAuthenticated={authentication.token}
          isIssara={profile.data.type === "IS"}
        />
        <AuthenticatedRoute
          path="/console-recruiter"
          layout={ConsoleRecruiterLayout}
          component={""}
          isAuthenticated={authentication.token}
        />
        <AuthenticatedRoute
          path="/console-employer"
          layout={ConsoleEmployerLayout}
          component={""}
          isAuthenticated={authentication.token}
        />
        <AuthenticatedRoute
          path="/not-valid-user/decide-redirection"
          layout={LandingLayout}
          component={DecideRedirection}
          isAuthenticated={authentication.token}
        />
        <AuthenticatedRoute
          exact
          path="/print/console-employer/jobs/:jobId/applications/:applicationId"
          layout={LandingLayout}
          component={EmployerApplicationsDetailedView}
          isAuthenticated={authentication.token}
        />
        <AuthenticatedRoute
          exact
          path="/print/console-recruiter/jobs/:jobId/applications/:applicationId"
          layout={LandingLayout}
          component={RecruiterApplicationDetailedView}
          isAuthenticated={authentication.token}
        />
        <UnauthenticatedRoute
          exact
          path="/login"
          layout={LandingLayout}
          component={LoginView}
          isAuthenticated={authentication.token}
        />
        <UnauthenticatedRoute
          exact
          path="/registration"
          layout={LandingLayout}
          component={RegistrationView}
          isAuthenticated={authentication.token}
        />
        <StandartRoute
          path="/email-verification/:uid64?/:token?"
          layout={LandingLayout}
          component={EmailVerificationView}
        />
        <StandartRoute
          path="/reset-password/:token?"
          layout={LandingLayout}
          component={PasswordResetView}
        />
        <AuthenticatedRoute
          exact
          path="/"
          layout={LandingLayout}
          component={LandingView}
          isAuthenticated={authentication.token}
        />
        // 403
        <StandartRoute
          path="/403"
          layout={LandingLayout}
          component={UnathorizedView}
        />
        // 404
        <StandartRoute
          path="/404"
          layout={LandingLayout}
          component={NotFoundView}
        />
        // Privacy Policy
        <StandartRoute
          path="/privacy-policy"
          layout={LandingLayout}
          component={PrivacyPolicy}
        />
        // Disclaimer
        <StandartRoute
          path="/disclaimer"
          layout={LandingLayout}
          component={Disclaimer}
        />
        <StandartRoute
          path="*"
          exact={true}
          layout={LandingLayout}
          component={NotFoundView}
        />
      </Switch>
    </Suspense>
  ) : (
    <div style={{ height: "100vh" }}>
      <div className="spinWrapper">
        <Spin tip={context.t("Loading..")} />
      </div>
    </div>
  );
}

App.contextTypes = {
  t: PropTypes.func.isRequired,
};
