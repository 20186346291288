import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const MY_JOBS_SET_FORM_DATA = "MY_JOBS_SET_FORM_DATA";

export const MY_JOBS_GET_START = "MY_JOBS_GET_START";
export const MY_JOBS_GET_SUCCESS = "MY_JOBS_GET_SUCCESS";
export const MY_JOBS_GET_FAIL = "MY_JOBS_GET_FAIL";

export const MY_JOBS_POST_START = "MY_JOBS_POST_START";
export const MY_JOBS_POST_SUCCESS = "MY_JOBS_POST_SUCCESS";
export const MY_JOBS_POST_FAIL = "MY_JOBS_POST_FAIL";

export const MY_JOBS_PATCH_START = "MY_JOBS_PATCH_START";
export const MY_JOBS_PATCH_SUCCESS = "MY_JOBS_PATCH_SUCCESS";
export const MY_JOBS_PATCH_FAIL = "MY_JOBS_PATCH_FAIL";

export const MY_JOBS_ARCHIVE_START = "MY_JOBS_ARCHIVE_START";
export const MY_JOBS_ARCHIVE_SUCCESS = "MY_JOBS_ARCHIVE_SUCCESS";
export const MY_JOBS_ARCHIVE_FAIL = "MY_JOBS_ARCHIVE_FAIL";

export const MY_JOBS_DELETE_START = "MY_JOBS_DELETE_START";
export const MY_JOBS_DELETE_SUCCESS = "MY_JOBS_DELETE_SUCCESS";
export const MY_JOBS_DELETE_FAIL = "MY_JOBS_DELETE_FAIL";

export const MY_JOBS_END_RECRUITMENT_START = "MY_JOBS_END_RECRUITMENT_START";
export const MY_JOBS_END_RECRUITMENT_SUCCESS =
  "MY_JOBS_END_RECRUITMENT_SUCCESS";
export const MY_JOBS_END_RECRUITMENT_FAIL = "MY_JOBS_END_RECRUITMENT_FAIL";

export const MY_JOBS_GO_ONLINE_SUCCESS = "MY_JOBS_GO_ONLINE_SUCCESS";
export const MY_JOBS_GO_OFFLINE_SUCCESS = "MY_JOBS_GO_OFFLINE_SUCCESS";

export const JOB_APPLICATION_CHANGE_STATUS_SUCCESS =
  "JOB_APPLICATION_CHANGE_STATUS_SUCCESS";

export const JOB_APPLICATION_SUBMITTED_NOTIFICATION =
  "JOB_APPLICATION_SUBMITTED_NOTIFICATION";
export const NOTIFICATION_JOB_APPLICATION_CHANGE_STATUS =
  "NOTIFICATION_JOB_APPLICATION_CHANGE_STATUS";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

export const setMyJobFormData = (id, data) => {
  return {
    type: MY_JOBS_SET_FORM_DATA,
    id: id,
    data: data,
  };
};

// GET:
export const getMyJobsStart = () => {
  return {
    type: MY_JOBS_GET_START,
  };
};

export const getMyJobsSuccess = (myJobs) => {
  return {
    type: MY_JOBS_GET_SUCCESS,
    myJobs: myJobs,
  };
};

export const getMyJobsFail = (error) => {
  return {
    type: MY_JOBS_GET_FAIL,
    error: error,
  };
};

export const getMyJobs = (type) => {
  return (dispatch, getState) => {
    dispatch(getMyJobsStart());
    return axios
      .get(`${process.env.REACT_APP_API_URL}/${type}-jobs/`)
      .then((res) => {
        dispatch(getMyJobsSuccess(res.data.results));
        // return getState().MyJobsState.items
      })
      .catch((err) => {
        dispatch(getMyJobsFail(err));
        throw err;
      });
  };
};

export const jobApplicationChangeStatusSuccess = (application) => {
  return {
    type: JOB_APPLICATION_CHANGE_STATUS_SUCCESS,
    application: application,
  };
};

export const viewJobApplication = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/view/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const downloadJobApplication = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/download/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const rejectJobApplication = (id, payload) => (dispatch) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/reject/`,
      payload
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const shortlistJobApplication = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/shortlist/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const contractSignApplications = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/contract-signed/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const requestDocumentsJobApplication = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/request_documents/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const offerJobApplication = (id, date) => (dispatch) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/offer/`,
      { offer_expired_at: date }
    )
    .then((res) => {
      dispatch(getMyJobs("recruiter"));
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateJobOfferExpiryDate = (id, date) => (dispatch) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/update-offer-expiry/`,
      { offer_expired_at: date }
    )
    .then((res) => {
      dispatch(getMyJobs("recruiter"));
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const waitlistJobApplication = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/waitlist/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const generalWaitlistJobApplication = (id, type) => (dispatch) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/business/job-applications/${id}/move-general-waitlist/`,
      { type }
    )
    .then((res) => {
      dispatch(getMyJobs("recruiter"));
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST:
export const postMyJobStart = () => {
  return {
    type: MY_JOBS_POST_START,
  };
};

export const postMyJobSuccess = (data) => {
  return {
    type: MY_JOBS_POST_SUCCESS,
    myJob: data,
  };
};

export const postMyJobFail = (error) => {
  return {
    type: MY_JOBS_POST_FAIL,
    error: error,
  };
};

export const postMyJob = (type, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(postMyJobStart());
      return axios
        .post(`${process.env.REACT_APP_API_URL}/${type}-jobs/`, payload)
        .then((res) => {
          dispatch(postMyJobSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(postMyJobFail(err));
          reject(err);
        });
    });
  };
};

// PATCH:
export const patchMyJobStart = () => {
  return {
    type: MY_JOBS_PATCH_START,
  };
};

export const patchMyJobSuccess = (data) => {
  return {
    type: MY_JOBS_PATCH_SUCCESS,
    myJob: data,
  };
};

export const patchMyJobFail = (error) => {
  return {
    type: MY_JOBS_PATCH_FAIL,
    error: error,
  };
};

export const patchMyJob = (type, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(patchMyJobStart());

      return axios
        .patch(
          `${process.env.REACT_APP_API_URL}/${type}-jobs/${payload.id}/`,
          payload
        )
        .then((res) => {
          dispatch(patchMyJobSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(patchMyJobFail(err));
          reject(err);
        });
    });
  };
};

// ARCHIVE:
export const archiveMyJobStart = () => {
  return {
    type: MY_JOBS_ARCHIVE_START,
  };
};

export const archiveMyJobSuccess = (data) => {
  return {
    type: MY_JOBS_ARCHIVE_SUCCESS,
    myJob: data,
  };
};

export const archiveMyJobFail = (error) => {
  return {
    type: MY_JOBS_ARCHIVE_FAIL,
    error: error,
  };
};

export const archiveMyJob = (type, id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(archiveMyJobStart());
      return axios
        .get(`${process.env.REACT_APP_API_URL}/${type}-jobs/${id}/archive/`)
        .then((res) => {
          dispatch(archiveMyJobSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(archiveMyJobFail(err));
          reject(err);
        });
    });
  };
};

// DELETE:
export const deleteMyJobStart = () => {
  return {
    type: MY_JOBS_DELETE_START,
  };
};

export const deleteMyJobSuccess = (id) => {
  return {
    type: MY_JOBS_DELETE_SUCCESS,
    id: id,
  };
};

export const deleteMyJobFail = (error) => {
  return {
    type: MY_JOBS_DELETE_FAIL,
    error: error,
  };
};

export const deleteMyJob = (type, id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteMyJobStart());
      return axios
        .delete(`${process.env.REACT_APP_API_URL}/${type}-jobs/${id}/`)
        .then((res) => {
          dispatch(deleteMyJobSuccess(id));
          resolve(id);
        })
        .catch((err) => {
          dispatch(deleteMyJobFail(err));
          reject(err);
        });
    });
  };
};

// GO ONLINE
export const goOnlineMyJobSuccess = (data) => {
  return {
    type: MY_JOBS_GO_ONLINE_SUCCESS,
    myJob: data,
  };
};

export const goOnlineMyJob = (type, id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${process.env.REACT_APP_API_URL}/${type}-jobs/${id}/online/`)
        .then((res) => {
          dispatch(goOnlineMyJobSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

// GO OFFLINE
export const goOfflineMyJobSuccess = (data) => {
  return {
    type: MY_JOBS_GO_OFFLINE_SUCCESS,
    myJob: data,
  };
};

export const goOfflineMyJob = (type, id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${process.env.REACT_APP_API_URL}/${type}-jobs/${id}/offline/`)
        .then((res) => {
          dispatch(goOfflineMyJobSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const jobApplicationSubmittedNotification = (application) => {
  return {
    type: JOB_APPLICATION_SUBMITTED_NOTIFICATION,
    application: application,
  };
};

export const jobApplicationStatusChangeNotification = (application) => {
  return {
    type: NOTIFICATION_JOB_APPLICATION_CHANGE_STATUS,
    application: application,
  };
};

// END RECRUITMENT
export const endRecruitmentMyJobStart = () => {
  return {
    type: MY_JOBS_END_RECRUITMENT_START,
  };
};

export const endRecruitmentMyJobSuccess = (data) => {
  return {
    type: MY_JOBS_END_RECRUITMENT_SUCCESS,
    myJob: data,
  };
};

export const endRecruitmentMyJobFail = (error) => {
  return {
    type: MY_JOBS_END_RECRUITMENT_FAIL,
    error: error,
  };
};

export const endRecruitmentMyJob = (type, id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(endRecruitmentMyJobStart());
      return axios
        .get(`${process.env.REACT_APP_API_URL}/${type}-jobs/${id}/close/`)
        .then((res) => {
          dispatch(endRecruitmentMyJobSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(endRecruitmentMyJobFail(err));
          reject(err);
        });
    });
  };
};
