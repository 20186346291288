import axios from 'axios';

import { addProfileSurvey } from './ProfileActions';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const SURVEYS_GET_START = 'SURVEYS_GET_START';
export const SURVEYS_GET_SUCCESS = 'SURVEYS_GET_SUCCESS';
export const SURVEYS_GET_FAIL = 'SURVEYS_GET_FAIL';

export const SURVEYS_ADMIN_GET_START = 'SURVEYS_ADMIN_GET_START';
export const SURVEYS_ADMIN_GET_SUCCESS = 'SURVEYS_ADMIN_GET_SUCCESS';
export const SURVEYS_ADMIN_GET_FAIL = 'SURVEYS_ADMIN_GET_FAIL';

export const SURVEYS_PARTICIPATION_POST_START = 'SURVEYS_PARTICIPATION_POST_START';
export const SURVEYS_PARTICIPATION_POST_SUCCESS = 'SURVEYS_PARTICIPATION_POST_SUCCESS';
export const SURVEYS_PARTICIPATION_POST_FAIL = 'SURVEYS_PARTICIPATION_POST_FAIL';

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getSurveysStart = () => {
  return {
    type: SURVEYS_GET_START,
  }
}

export const getSurveysSuccess = (surveys) => {
  return {
    type: SURVEYS_GET_SUCCESS,
    surveys: surveys,
  }
}

export const getSurveysFail = error => {
  return {
    type: SURVEYS_GET_FAIL,
    error: error
  }
}

export const getSurveys = () => {
  return (dispatch, getState) => {
      dispatch(getSurveysStart());
      const language = getState().i18nState.lang
      return axios.get(`${process.env.REACT_APP_API_URL}/surveys/?lang=${language}`)
      .then(res => {
          dispatch(getSurveysSuccess(res.data));
          return getState().SurveysState.items
      })
      .catch(err => {
          dispatch(getSurveysFail(err))
          throw err
      })
  }
}

export const getSurveysAdminStart = () => {
  return {
    type: SURVEYS_ADMIN_GET_START,
  }
}

export const getSurveysAdminSuccess = (surveys) => {
  return {
    type: SURVEYS_ADMIN_GET_SUCCESS,
    surveys: surveys,
  }
}

export const getSurveysAdminFail = error => {
  return {
    type: SURVEYS_ADMIN_GET_FAIL,
    error: error
  }
}

export const getSurveysAdmin = () => {
  return (dispatch, getState) => {
      dispatch(getSurveysAdminStart());
      const language = getState().i18nState.lang

      return axios.get(`${process.env.REACT_APP_API_URL}/admin/surveys/?lang=${language}`)
      .then(res => {
          dispatch(getSurveysAdminSuccess(res.data.results));
          return getState().SurveysState.items
      })
      .catch(err => {
          dispatch(getSurveysAdminFail(err))
          throw err
      })
  }
}

// POST:
export const postSurveyParticipationStart = () => {
  return {
    type: SURVEYS_PARTICIPATION_POST_START,
  }
}

export const postSurveyParticipationSuccess = () => {
  return {
    type: SURVEYS_PARTICIPATION_POST_SUCCESS,
  }
}

export const postSurveyParticipationFail = error => {
  return {
    type: SURVEYS_PARTICIPATION_POST_FAIL,
  }
}

export const postSurveyParticipation  = (payload, surveyIdentifier) => {
  return (dispatch, getState) => {
      dispatch(postSurveyParticipationStart());
      return axios.post(`${process.env.REACT_APP_API_URL}/survey-participations/`, payload)
      .then(res => {
          dispatch(postSurveyParticipationSuccess());
          dispatch(addProfileSurvey(surveyIdentifier))
          return res.data
      })
      .catch(err => {
          dispatch(postSurveyParticipationFail(err))
          throw err
      })
  }
}
