import { updateObject } from '../Utility';
import {
  VERIFY_BUSINESS_START, VERIFY_BUSINESS_SUCCESS, VERIFY_BUSINESS_FAIL
} from '../actions/AdminActions'


const initialState = {
  businessVerfication: {
    loading: false,
    id: null,
    error: null
  },
}

// VERIFY BUSINESS
const verifyBusinessStart = (state, action) => {
  return updateObject(state, {
    businessVerfication: {
      loading: true,
      id: action.id,
      error: null
    },
  });
}

const verifyBusinessSuccess = (state, action) => {
  return updateObject(state, {
    businessVerfication: {
      loading: false,
      id: null,
      error: null
    },
  });
}

const verifyBusinessFail = (state, action) => {
  return updateObject(state, {
    businessVerfication: {
      loading: false,
      id: null,
      error: action.error
    },
  });
}


const AdminReducer = (state=initialState, action) => {
  switch (action.type) {

    // VERIFY BUSINESS:
    case VERIFY_BUSINESS_START: return verifyBusinessStart(state, action);
    case VERIFY_BUSINESS_SUCCESS: return verifyBusinessSuccess(state, action);
    case VERIFY_BUSINESS_FAIL: return verifyBusinessFail(state, action);

    default:
      return state;
  }
}

export default AdminReducer;
