import axios from 'axios';

// zawgyi-unicode converter
const Rabbit = require("rabbit-node");


// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const RECRUITMENT_AGENCIES_GET_START = 'RECRUITMENT_AGENCIES_GET_START';
export const RECRUITMENT_AGENCIES_GET_SUCCESS = 'RECRUITMENT_AGENCIES_GET_SUCCESS';
export const RECRUITMENT_AGENCIES_GET_FAIL = 'RECRUITMENT_AGENCIES_GET_FAIL';

export const EXTENDED_RECRUITMENT_AGENCIES_GET_SUCCESS = 'EXTENDED_RECRUITMENT_AGENCIES_GET_SUCCESS';

export const RECRUITMENT_AGENCIES_ADD_COMMENT = 'RECRUITMENT_AGENCIES_ADD_COMMENT';
export const RECRUITMENT_AGENCIES_ADD_CHILD_COMMENT = 'RECRUITMENT_AGENCIES_ADD_CHILD_COMMENT';

export const RECRUITMENT_AGENCY_ADD_COMMENT = 'RECRUITMENT_AGENCY_ADD_COMMENT';
export const RECRUITMENT_AGENCY_ADD_CHILD_COMMENT = 'RECRUITMENT_AGENCY_ADD_CHILD_COMMENT';

export const RECRUITMENT_AGENCIES_RECALCULATE_SCORE = 'RECRUITMENT_AGENCIES_RECALCULATE_SCORE';

export const RECRUITMENT_AGENCIES_VERIFY_NEW = 'RECRUITMENT_AGENCIES_VERIFY_NEW';
export const RECRUITMENT_AGENCIES_REJECT_NEW = 'RECRUITMENT_AGENCIES_REJECT_NEW';
export const RECRUITMENT_AGENCIES_MERGE_NEW = 'RECRUITMENT_AGENCIES_MERGE_NEW';

export const PENDING_RA_START = 'PENDING_RA_START';
export const PENDING_RA_SUCCESS = 'PENDING_RA_SUCCESS';
export const PENDING_RA_FAIL = 'PENDING_RA_FAIL'

export const VERIFIED_RA_START = 'VERIFIED_RA_START'
export const VERIFIED_RA_SUCCESS = 'VERIFIED_RA_SUCCESS'
export const VERIFIED_RA_FAIL = 'VERIFIED_RA_FAIL'

export const GET_RA_DETAIL_START = 'GET_RA_DETAIL_START';
export const GET_RA_DETAIL_FAIL = 'GET_RA_DETAIL_FAIL';
export const GET_RA_DETAIL_SUCCESS = 'GET_RA_DETAIL_SUCCESS';

export const RA_COMMENT_REMOVE = 'RA_COMMENT_REMOVE';

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getRecruitmentAgenciesStart = () => {
  return {
    type: RECRUITMENT_AGENCIES_GET_START,
  }
}

export const getRecruitmentAgenciesSuccess = (recruitmentAgencies) => {
  return {
    type: RECRUITMENT_AGENCIES_GET_SUCCESS,
    recruitmentAgencies: recruitmentAgencies,
  }
}

export const getRecruitmentAgenciesFail = error => {
  return {
    type: RECRUITMENT_AGENCIES_GET_FAIL,
    error: error
  }
}

export const getRecruitmentAgencies = () => {
  return (dispatch, getState) => {
    dispatch(getRecruitmentAgenciesStart());

    const language = getState().i18nState.lang

    axios.get(`${process.env.REACT_APP_API_URL}/recruitment-agencies?lang=${language}`)
      .then(res => {

        let recruitmentAgencies = res.data

        // add unicode names to be used in the search later
        if (language === 'mm-z') {
          recruitmentAgencies = res.data.results.map(recruitmentAgency => {
            return { ...recruitmentAgency, name_mm_uni: Rabbit.zg2uni(recruitmentAgency.name) }
          })
        }

        dispatch(getRecruitmentAgenciesSuccess(recruitmentAgencies));
        return getState().RecruitmentAgenciesState.items
      })
      .catch(err => {
        dispatch(getRecruitmentAgenciesFail(err))
        throw err
      })
  }
}

export const getExtendedRecruitmentAgenciesSuccess = (extendedRecruitmentAgencies, status) => {
  return {
    type: EXTENDED_RECRUITMENT_AGENCIES_GET_SUCCESS,
    extendedRecruitmentAgencies: extendedRecruitmentAgencies,
    status: status,
  }
}

export const getExtendedRecruitmentAgencies = () => {
  return (dispatch, getState) => {
    dispatch(getRecruitmentAgenciesStart());

    let url = `${process.env.REACT_APP_API_URL}/recruitment-agencies-extended`

    axios.get(url)
      .then(res => {
        dispatch(getExtendedRecruitmentAgenciesSuccess(res.data));
      })
      .catch(err => {
        dispatch(getRecruitmentAgenciesFail(err))
      })
  }
}

// get pending ras
export const getPendingRaSuccess = (ras) => {
  return {
    type: PENDING_RA_SUCCESS,
    ras: ras,
  }
}

export const getPendingRaFail = error => {
  return {
    type: PENDING_RA_FAIL,
    error: error
  }
}

export const getPendingRaStart = () => {
  return {
    type: PENDING_RA_START
  }
}


// get verified RA
export const getVerifiedRaSuccess = (ras) => {
  return {
    type: VERIFIED_RA_SUCCESS,
    ras: ras,
  }
}

export const getVerifiedRaFail = error => {
  return {
    type: VERIFIED_RA_FAIL,
    error: error
  }
}

export const getVerifiedRaStart = () => {
  return {
    type: VERIFIED_RA_START
  }
}

export const RaCommentRemove = comment => {
  return {
   type: RA_COMMENT_REMOVE,
   comment: comment
  }
}

export const getVerifiedRa = () => {
  return (dispatch, getState) => {
    dispatch(getVerifiedRaStart());

    let url = `${process.env.REACT_APP_API_URL}/recruitment-agencies-extended?managed_by=User&email_status=true&user_type=RA`

    axios.get(url)
      .then(res => {
        dispatch(getVerifiedRaSuccess(res.data));
      })
      .catch(err => {
        dispatch(getVerifiedRaFail(err))
      })
  }

}

export const getPendingRa = () => {
  return (dispatch, getState) => {
    dispatch(getPendingRaStart());

    let url = `${process.env.REACT_APP_API_URL}/recruitment-agencies-extended?managed_by=Pending&email_status=true&user_type=RA`

    axios.get(url)
      .then(res => {
        dispatch(getPendingRaSuccess(res.data));
      })
      .catch(err => {
        dispatch(getPendingRaFail(err))
      })
  }

}

// OTHER:
export const addRecruitmentAgenciesComment = (comment) => {
  return {
    type: RECRUITMENT_AGENCIES_ADD_COMMENT,
    comment: comment
  }
}

export const addRecruitmentAgenciesChildComment = (comment) => {
  return {
    type: RECRUITMENT_AGENCIES_ADD_CHILD_COMMENT,
    comment: comment,
  }
}

// ONE BY ONE ADDING COMMENT:
export const addRecruitmentAgencyComment = (comment) => {
  return {
    type: RECRUITMENT_AGENCY_ADD_COMMENT,
    comment: comment
  }
}

export const addRecruitmentAgencyChildComment = (comment) => {
  return {
    type: RECRUITMENT_AGENCY_ADD_CHILD_COMMENT,
    comment: comment,
  }
}

export const recalculateRecruitmentAgencyScore = (
  recruitment_agency_id, detailed_scores, offsetCount, offsetScore, update = false
) => {
  return {
    type: RECRUITMENT_AGENCIES_RECALCULATE_SCORE,
    recruitment_agency_id: recruitment_agency_id,
    detailed_scores: detailed_scores,
    offsetCount: offsetCount,
    offsetScore: offsetScore,
    update: update
  }
}

export const verifiyNewRecruitmentAgency = (id) => {
  return {
    type: RECRUITMENT_AGENCIES_VERIFY_NEW,
    id: id
  }
}

export const rejectNewRecruitmentAgency = (id) => {
  return {
    type: RECRUITMENT_AGENCIES_REJECT_NEW,
    id: id
  }
}

export const mergeNewRecruitmentAgency = (primaryId, secondaryId) => {
  return {
    type: RECRUITMENT_AGENCIES_REJECT_NEW,
    primaryId: primaryId,
    secondaryId: secondaryId
  }
}

// GET RA Detail:
export const getRecruiterDetailStart = () => {
  return {
    type: GET_RA_DETAIL_START,
  }
}

export const getRecruiterDetailSuccess = (recruiter) => {
  return {
    type: GET_RA_DETAIL_SUCCESS,
    recruiter: recruiter,
  }
}

export const getRecruiterDetailFail = error => {
  return {
    type: GET_RA_DETAIL_FAIL,
    error: error
  }
}


export const getRecruiter = (id) => {
  return (dispatch, getState) => {
    dispatch(getRecruiterDetailStart());

    const language = getState().i18nState.lang

    axios.get(`${process.env.REACT_APP_API_V2_URL}/recruitment-agencies/${id}?lang=${language}`)
      .then(res => {
        let recruiter = res.data

        // add unicode names to be used in the search later
        if (language === 'mm-z') {
          recruiter = { ...recruiter, name_mm_uni: recruiter && recruiter.name && Rabbit.zg2uni(recruiter.name) }
        }
        dispatch(getRecruiterDetailSuccess(recruiter));
        return getState().RecruitmentAgenciesState.detail.item
      })
      .catch(err => {
        dispatch(getRecruiterDetailFail(err))
        throw err
      })
  }
}

export const getPendingRasByUrl = (url) => {
  return (dispatch, getState) => {
    dispatch(getPendingRaStart());
    
    axios.get(url)
      .then(res => {
        dispatch(getPendingRaSuccess(res.data));
      })
      .catch(err => {
        dispatch(getPendingRaFail(err))
      })
  }
}

export const getVerifiedRasByUrl = (url) => {
  return (dispatch, getState) => {
    dispatch(getVerifiedRaStart());
    axios.get(url)
      .then(res => {
        dispatch(getVerifiedRaSuccess(res.data));
      })
      .catch(err => {
        dispatch(getVerifiedRaFail(err))
      })
  }
}