import axios from 'axios';

import { anonymizeComment } from '../../utilities/utilities'

import { addGuidesComment, addGuidesChildComment, deleteGuidesComment } from './GuidesActions';
import { addNewsComment, addNewsChildComment, deleteNewsComment } from './NewsActions';
import { addServiceProvidersComment, addServiceProvidersChildComment } from './ServiceProvidersActions';
import { addRecruitmentAgencyComment, addRecruitmentAgencyChildComment } from './RecruitmentAgenciesActions';
import { addEmployerComment, addEmployerChildComment } from './EmployersActions';
import { addTopicQuestionsComment, addTopicQuestionsChildComment } from './GroupsActions';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const COMMENTS_GET_START = 'COMMENTS_GET_START';
export const COMMENTS_GET_SUCCESS = 'COMMENTS_GET_SUCCESS';
export const COMMENTS_GET_FAIL = 'COMMENTS_GET_FAIL';

export const COMMENT_POST_START = 'COMMENT_POST_START';
export const COMMENT_POST_SUCCESS = 'COMMENT_POST_SUCCESS';
export const COMMENT_POST_FAIL = 'COMMENT_POST_FAIL';

export const COMMENT_DELETE_START = 'COMMENT_DELETE_START';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAIL = 'COMMENT_DELETE_FAIL';

export const COMMENT_ADD_REPLY = 'COMMENT_ADD_REPLY';
export const COMMENT_DELETE_REPLY = 'COMMENT_DELETE_REPLY';

export const GOT_COMMENT_RESPONSE = 'GOT_COMMENT_RESPONSE';

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getCommentsStart = () => {
  return {
    type: COMMENTS_GET_START,
  }
}

export const getCommentsSuccess = (comments) => {
  return {
    type: COMMENTS_GET_SUCCESS,
    comments: comments,
  }
}

export const getCommentsFail = error => {
  return {
    type: COMMENTS_GET_FAIL,
    error: error
  }
}

export const getComments = () => {
  return (dispatch, getState) => {
    dispatch(getCommentsStart());

    const language = getState().i18nState.lang
    axios.get(`${process.env.REACT_APP_API_URL}/comments?lang=${language}`)
      .then(res => {
        dispatch(getCommentsSuccess(res.data));
        return getState().CommentsState.items
      })
      .catch(err => {
        dispatch(getCommentsFail(err))
        throw err
      })
  }
}

// POST:
export const postCommentStart = () => {
  return {
    type: COMMENT_POST_START,
  }
}

export const postCommentSuccess = comment => {
  return {
    type: COMMENT_POST_SUCCESS,
    comment: comment
  }
}

export const postCommentFail = error => {
  return {
    type: COMMENT_POST_FAIL,
    error: error
  }
}

export const postComment = (content_type, payload) => {
  return (dispatch, getState) => {
    dispatch(postCommentStart());

    const userType = getState().ProfileState.data.type

    axios.post(`${process.env.REACT_APP_API_URL}/comments?content_type=${content_type}`, payload)
      .then(res => {

        dispatch(getCommentResponse(res.data));
        
        if (payload.base_parent) {

          delete res.data['children']

          if (content_type === 'guide') {
            dispatch(addGuidesChildComment(res.data))
          }
          else if (content_type === 'news') {
            dispatch(addNewsChildComment(res.data))
          }
          else if (content_type === 'topic_question') {
            res.data['topic_id'] = payload.topic_id
            dispatch(addTopicQuestionsChildComment(res.data))
          }
          else if (content_type === 'service_provider') {
            userType === 'JS' && anonymizeComment(res.data)
            dispatch(addServiceProvidersChildComment(res.data))
          }
          else if (content_type === 'recruitment_agency') {
            userType === 'JS' && anonymizeComment(res.data)
            dispatch(addRecruitmentAgencyChildComment(res.data))
          }
          else if (content_type === 'employer') {
            userType === 'JS' && anonymizeComment(res.data)
            dispatch(addEmployerChildComment(res.data))
          }
        }
        else {
          if (content_type === 'guide') {
            dispatch(addGuidesComment(res.data))
          }
          else if (content_type === 'news') {
            dispatch(addNewsComment(res.data))
          }
          else if (content_type === 'topic_question') {
            res.data['topic_id'] = payload.topic_id
            dispatch(addTopicQuestionsComment(res.data))
          }
          else if (content_type === 'service_provider') {
            userType === 'JS' && anonymizeComment(res.data)
            dispatch(addServiceProvidersComment(res.data))
          }
          else if (content_type === 'recruitment_agency') {
            userType === 'JS' && anonymizeComment(res.data)
            dispatch(addRecruitmentAgencyComment(res.data))
          }
          else if (content_type === 'employer') {
            userType === 'JS' && anonymizeComment(res.data)
            dispatch(addEmployerComment(res.data))
          }
        }
      })
      .catch(err => {
        console.log(err)
        dispatch(postCommentFail(err))
      })
  }
}

// ADD:
export const addCommentReply = (parent_id, comment_id) => {
  return {
    type: COMMENT_ADD_REPLY,
    parent_id: parent_id,
    comment_id: comment_id
  }
}


// DELETE:
export const deleteCommentStart = () => {
  return {
    type: COMMENT_DELETE_START,
  }
}

export const deleteCommentSuccess = comment_id => {
  return {
    type: COMMENT_DELETE_SUCCESS,
    comment_id: comment_id,
  }
}

export const getCommentResponse = comment => {
  return {
    type: GOT_COMMENT_RESPONSE,
    comment: comment
  };
}

export const deleteCommentFail = error => {
  return {
    type: COMMENT_DELETE_FAIL,
    error: error
  }
}

export const deleteCommentReply = (parent_id, comment_id) => {
  return {
    type: COMMENT_DELETE_REPLY,
    parent_id: parent_id,
    comment_id: comment_id
  }
}

export const deleteComment = (content_type, object_id, comment_id, parent_id) => {
  return dispatch => {
    dispatch(deleteCommentStart());
    console.log(`COMMENT: ${comment_id}, PARENT: ${parent_id}`)
    axios.delete(`${process.env.REACT_APP_API_URL}/comments/${comment_id}`)
      .then(res => {
        if (parent_id) {
          dispatch(deleteCommentReply(parent_id, comment_id))
        }
        else {
          if (content_type === 'guide') {
            dispatch(deleteGuidesComment(object_id, comment_id))
          }
          else if (content_type === 'news') {
            dispatch(deleteNewsComment(object_id, comment_id))
          }
        }
        dispatch(deleteCommentSuccess(comment_id));
      })
      .catch(err => {
        dispatch(deleteCommentFail(err))
      })
  }
}
