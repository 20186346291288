import axios from 'axios';

// zawgyi-unicode converter
const Rabbit = require("rabbit-node");

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const SERVICE_PROVIDERS_GET_START = 'SERVICE_PROVIDERS_GET_START';
export const SERVICE_PROVIDERS_GET_SUCCESS = 'SERVICE_PROVIDERS_GET_SUCCESS';
export const SERVICE_PROVIDERS_GET_FAIL = 'SERVICE_PROVIDERS_GET_FAIL';

export const SERVICE_PROVIDERS_ADD_COMMENT = 'SERVICE_PROVIDERS_ADD_COMMENT';
export const SERVICE_PROVIDERS_ADD_CHILD_COMMENT = 'SERVICE_PROVIDERS_ADD_CHILD_COMMENT';
export const SERVICE_PROVIDERS_RECALCULATE_SCORE = 'SERVICE_PROVIDERS_RECALCULATE_SCORE';
// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getServiceProvidersStart = () => {
  return {
    type: SERVICE_PROVIDERS_GET_START,
  }
}

export const getServiceProvidersSuccess = (serviceProviders) => {
  return {
    type: SERVICE_PROVIDERS_GET_SUCCESS,
    serviceProviders: serviceProviders,
  }
}

export const getServiceProvidersFail = error => {
  return {
    type: SERVICE_PROVIDERS_GET_FAIL,
    error: error
  }
}

export const getServiceProviders = () => {
  return (dispatch, getState) => {
      dispatch(getServiceProvidersStart());

      const language = getState().i18nState.lang

      axios.get(`${process.env.REACT_APP_API_URL}/service-providers?lang=${language}`)
      .then(res => {
        let serviceProviders = res.data

        // add unicode names to be used in the search later
        if (language === 'mm-z') {
          serviceProviders = serviceProviders.map(serviceProvider => {
            return {...serviceProvider, name_mm_uni: Rabbit.zg2uni(serviceProvider.name)}
          })
        }
        
        dispatch(getServiceProvidersSuccess(serviceProviders));
        return getState().ServiceProvidersState.items
      })
      .catch(err => {
        dispatch(getServiceProvidersFail(err))
        throw err
      })
  }
}


// OTHER:
export const addServiceProvidersComment = (comment) => {
  return {
    type: SERVICE_PROVIDERS_ADD_COMMENT,
    comment: comment
  }
}

export const addServiceProvidersChildComment = (comment) => {
  return {
    type: SERVICE_PROVIDERS_ADD_CHILD_COMMENT,
    comment: comment,
  }
}

export const recalculateServiceProviderScore = (
  service_provider_id, detailed_scores, offsetCount, offsetScore, update=false
) => {
  return {
    type: SERVICE_PROVIDERS_RECALCULATE_SCORE,
    service_provider_id: service_provider_id,
    detailed_scores: detailed_scores,
    offsetCount: offsetCount,
    offsetScore: offsetScore,
    update: update
  }
}
