import { updateObject } from '../Utility';
import { INDUSTRIES_GET_START, INDUSTRIES_GET_SUCCESS,
         INDUSTRIES_GET_FAIL } from '../actions/IndustriesActions'


const initialState = {
  items: {},
  itemsArray: [],
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getIndustriesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getIndustriesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.industries.reduce((obj, item) => {
       obj[item.industry] = item
       return obj
     }, {}),
    itemsArray: action.industries,
    loading: false,
    fetched: true,
    error: null,
  });
}

const getIndustriesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


const IndustriesReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case INDUSTRIES_GET_START: return getIndustriesStart(state, action);
    case INDUSTRIES_GET_SUCCESS: return getIndustriesSuccess(state, action);
    case INDUSTRIES_GET_FAIL: return getIndustriesFail(state, action);

    default:
      return state;
  }
}

export default IndustriesReducer;
