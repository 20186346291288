import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const MY_ROSTER_APPLICATIONS_GET_START =
  "MY_ROSTER_APPLICATIONS_GET_START";
export const MY_ROSTER_APPLICATIONS_GET_SUCCESS =
  "MY_ROSTER_APPLICATIONS_GET_SUCCESS";
export const MY_ROSTER_APPLICATIONS_GET_FAIL =
  "MY_ROSTER_APPLICATIONS_GET_FAIL";

export const MY_ROSTER_APPLICATIONS_POST_START =
  "MY_ROSTER_APPLICATIONS_POST_START";
export const MY_ROSTER_APPLICATIONS_POST_SUCCESS =
  "MY_ROSTER_APPLICATIONS_POST_SUCCESS";
export const MY_ROSTER_APPLICATIONS_POST_FAIL =
  "MY_ROSTER_APPLICATIONS_POST_FAIL";

export const EXTEND_ROSTER_APPLICATION_START =
  "EXTEND_ROSTER_APPLICATION_START";
export const EXTEND_ROSTER_APPLICATION_SUCCESS =
  "EXTEND_ROSTER_APPLICATION_SUCCESS";
export const EXTEND_ROSTER_APPLICATION_FAIL = "EXTEND_ROSTER_APPLICATION_FAIL";

export const WITHDRAW_ROSTER_APPLICATION_START =
  "WITHDRAW_ROSTER_APPLICATION_START";
export const WITHDRAW_ROSTER_APPLICATION_SUCCESS =
  "WITHDRAW_ROSTER_APPLICATION_SUCCESS";
export const WITHDRAW_ROSTER_APPLICATION_FAIL =
  "WITHDRAW_ROSTER_APPLICATION_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getMyRosterApplicationsStart = () => {
  return {
    type: MY_ROSTER_APPLICATIONS_GET_START,
  };
};

export const getMyRosterApplictionsSuccess = (myRosterApplications) => {
  return {
    type: MY_ROSTER_APPLICATIONS_GET_SUCCESS,
    myRosterApplications: myRosterApplications,
  };
};

export const getMyRosterApplicationsFail = (error) => {
  return {
    type: MY_ROSTER_APPLICATIONS_GET_FAIL,
    error: error,
  };
};

export const getMyRosterApplications = (id) => {
  return (dispatch, getState) => {
    dispatch(getMyRosterApplicationsStart());
    return axios
      .get(`${process.env.REACT_APP_API_V2_URL}/roster-applications`)
      .then((res) => {
        dispatch(getMyRosterApplictionsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getMyRosterApplicationsFail(err));
        throw err;
      });
  };
};

// POST:
export const postMyRosterApplicationsStart = () => {
  return {
    type: MY_ROSTER_APPLICATIONS_POST_START,
  };
};

export const postMyRosterApplicationsSuccess = (data) => {
  return {
    type: MY_ROSTER_APPLICATIONS_POST_SUCCESS,
    myRosterApplication: data,
  };
};

export const postMyRosterApplicationsFail = (error) => {
  return {
    type: MY_ROSTER_APPLICATIONS_POST_FAIL,
    error: error,
  };
};

// EXTEND
export const extendRosterApplicationStart = () => {
  return {
    type: EXTEND_ROSTER_APPLICATION_START,
  };
};

export const extendRosterApplicationSuccess = (application) => {
  return {
    type: EXTEND_ROSTER_APPLICATION_SUCCESS,
    application: application,
  };
};

export const extendRosterApplicationFail = (error) => {
  return {
    type: EXTEND_ROSTER_APPLICATION_FAIL,
    error: error,
  };
};

export const postMyRosterApplication = (payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(postMyRosterApplicationsStart());
      return axios
        .post(`${process.env.REACT_APP_API_URL}/roster-applications/`, payload)
        .then((res) => {
          dispatch(postMyRosterApplicationsSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(postMyRosterApplicationsFail(err));
          reject(err);
        });
    });
  };
};

export const extendRosterApplication = (rosterId) => {
  return (dispatch, getState) => {
    dispatch(extendRosterApplicationStart());

    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/roster-applications/${rosterId}/extend/`
      )
      .then((res) => {
        dispatch(extendRosterApplicationSuccess(res.data));
      })
      .catch((err) => {
        dispatch(extendRosterApplicationFail(err));
        throw err;
      });
  };
};

// WITHDRAW
export const withdrawRosterApplicationStart = () => {
  return {
    type: WITHDRAW_ROSTER_APPLICATION_START,
  };
};

export const withdrawRosterApplicationSuccess = (detail) => {
  return {
    type: WITHDRAW_ROSTER_APPLICATION_SUCCESS,
    message: detail,
  };
};

export const withdrawRosterApplicationFail = (error) => {
  return {
    type: WITHDRAW_ROSTER_APPLICATION_FAIL,
    error: error,
  };
};

export const withdrawRosterApplication = (rosterId) => {
  return (dispatch, getState) => {
    dispatch(withdrawRosterApplicationStart());
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/roster-applications/${rosterId}/withdraw/`
      )
      .then((res) => {
        if (res && res.data) {
          dispatch(withdrawRosterApplicationSuccess(res.data.detail));
        }
      })
      .catch((err) => {
        dispatch(withdrawRosterApplicationFail(err));
        throw err;
      });
  };
};

export const withdrawAllRosterApplication = (jobseekerId) => {
  return (dispatch, getState) => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/jobseekers/${jobseekerId}/withdraw-all-applications`
      )
      .then((res) => {
        console.log("withdrew", res);
      })
      .catch((err) => {
        throw err;
      });
  };
};

export const extendAllRosterApplication = (jobseekerId) => {
  return (dispatch, getState) => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/jobseekers/${jobseekerId}/extend-all-roster-applications`
      )
      .then((res) => {
        console.log("extended", res);
      })
      .catch((err) => {
        throw err;
      });
  };
};
