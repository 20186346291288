// import built-in modules
import React, { useState } from "react";
import PropTypes from 'prop-types';

// Imoprt third-party modules
import axios from 'axios';
import { Button, Modal, Form, Input, message } from 'antd';

// Import styles
import styles from './ForgotPasswordModal.module.css';


export default function ForgotPasswordModal(props, context) {

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onFinish = (values) => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_URL}/authentication/password-reset/`, values).then(res => {
      props.onCancel();
      message.success(context.t(`Password reset link was successfully sent to {email}. Please check your inbox.`, {email: values.email}), 10)
    })
    .catch(error => {
      if (error.response) {
        setError(error.response.data.email[0])
      } else if (error.request) {
        setError(error.request)
      } else {
        setError(error.message)
      }
    }).finally(() => {
      setLoading(false)
      setTimeout(() => setError(null), 6000)
    })
  };

  return (
    <Modal
      title={context.t('Reset your password')}
      visible={props.visible}
      onCancel={props.onCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="register"
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        scrollToFirstError
        preserve={false}
      >
        <Form.Item
          name="email"
          label={context.t('Email')}
          initialValue={props.prepopulatedEmail}
          rules={[
            {
              type: 'email',
              message: context.t('The input is not valid Email'),
            },
            {
              required: true,
              message: context.t('Please input your Email'),
            },
          ]}
          {...error && {
              help: error,
              validateStatus: 'error',
          }}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item style={{marginBottom: '0.5rem'}}>
          <Button
            type="primary"
            htmlType="submit"
            style={{width: '100%', marginTop: '0.5rem'}}
            loading={loading}
          >
            {context.t('Send reset link')}
          </Button>
        </Form.Item>
        <p className={styles.helpText}>
          {context.t('We will send password reset link to this email address, please change your password within 24 hours.')}
        </p>
      </Form>
    </Modal>
  )
}

ForgotPasswordModal.contextTypes = {
  t: PropTypes.func.isRequired
}
