import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const GUIDE_SUBCATEGORIES_GET_START = 'GUIDE_SUBCATEGORIES_GET_START';
export const GUIDE_SUBCATEGORIES_GET_SUCCESS = 'GUIDE_SUBCATEGORIES_GET_SUCCESS';
export const GUIDE_SUBCATEGORIES_GET_FAIL = 'GUIDE_SUBCATEGORIES_GET_FAIL';


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getGuideSubcategoriesStart = () => {
  return {
    type: GUIDE_SUBCATEGORIES_GET_START,
  }
}

export const getGuideSubcategoriesSuccess = (guideSubcategories) => {
  return {
    type: GUIDE_SUBCATEGORIES_GET_SUCCESS,
    GuideSubcategories: guideSubcategories,
  }
}

export const getGuideSubcategoriesFail = error => {
  return {
    type: GUIDE_SUBCATEGORIES_GET_FAIL,
    error: error
  }
}

export const getGuideSubcategories = () => {
  return (dispatch, getState) => {
      dispatch(getGuideSubcategoriesStart());
      const language = getState().i18nState.lang
      return axios.get(`${process.env.REACT_APP_API_URL}/guide-subcategories?lang=${language}`)
      .then(res => {
          dispatch(getGuideSubcategoriesSuccess(res.data));
          return getState().GuideSubcategoriesState.items
      })
      .catch(err => {
          dispatch(getGuideSubcategoriesFail(err))
          throw err
      })
  }
}
