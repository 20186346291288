import { updateObject } from '../Utility';
import {
  FILTERS_SORTERS_SET_FILTER,
  FILTERS_SORTERS_SET_FILTER_BY_STATIC_VALUE,
  FILTERS_SORTERS_SET_SORT,
  FILTERS_SORTERS_SET_SEARCH,
  FILTERS_SORTERS_SET_SCROLL_TO_INDEX,
  FILTERS_SORTERS_RESET_ALL
} from '../actions/FiltersSortersActions'

let country = localStorage.getItem('currentCountry')
// RA country array
const checkCountry = [2, 3, 9]

if (country) {
  country = parseInt(country)
}

const initialState = {
  sort: {
    guides: 'Date: Newest',
    news: 'Date: Newest',
    topics: 'Popularity',
    employers: 'Popularity',
    recruitmentAgencies: 'Popularity',
    serviceProviders: 'Popularity',
    jobs: 'Posted: Newest'
  },
  filters: {
    guides: { category: 0, country: 0, useDefaultCountry: true },
    news: { country: 0, useDefaultCountry: true },
    topics: { country: 0, useDefaultCountry: true },
    employers: { industry: 0, country: 0, useDefaultCountry: true },
    recruitmentAgencies: { industry: 0, country: 0, useDefaultCountry: true },
    serviceProviders: { category: 0, country: 0, useDefaultCountry: true },
    jobs: {
      employer_industry: 0, recruitment_agency: checkCountry.includes(country) ? true : !checkCountry.includes(country) ? false : 'no-resume'
    }
  },
  search: {
    jobs: '',
    guides: '',
    news: '',
    topics: '',
    employers: '',
    recruitmentAgencies: '',
    serviceProviders: '',
    recruitersRoster: '',
  },
  scrollToIndex: {
    applications: undefined,
    jobs: undefined,
    notifications: undefined,
    recruitersRoster: undefined,
  }
}

const setFilter = (state, action) => {
  return updateObject(state, {
    ...state,
    filters: {
      ...state.filters,
      [action.contentType]: {
        ...state.filters[action.contentType],
        [action.filterType]: action.filter
      }
    }
  });
}

const setFilterByStaticValue = (state, action) => {
  return updateObject(state, {
    ...state,
    filters: {
      ...state.filters,
      [action.contentType]: {
        ...state.filters[action.contentType],
        [action.filterType]: action.filter
      }
    }
  });
}

const setSort = (state, action) => {
  return updateObject(state, {
    ...state,
    sort: {
      ...state.sort,
      [action.contentType]: action.sort
    }
  });
}

const setSearch = (state, action) => {
  return updateObject(state, {
    ...state,
    search: {
      ...state.search,
      [action.contentType]: action.search
    }
  });
}

const setScrollToIndex = (state, action) => {
  return updateObject(state, {
    ...state,
    scrollToIndex: {
      ...state.scrollToIndex,
      [action.contentType]: action.scrollToIndex
    }
  });
}

const resetAll = (state, action) => {
  return updateObject(state, {
    ...state,
    ...initialState
  });
}


const FiltersSortersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTERS_SORTERS_SET_FILTER: return setFilter(state, action);
    case FILTERS_SORTERS_SET_FILTER_BY_STATIC_VALUE: return setFilterByStaticValue(state, action);
    case FILTERS_SORTERS_SET_SORT: return setSort(state, action);
    case FILTERS_SORTERS_SET_SEARCH: return setSearch(state, action);
    case FILTERS_SORTERS_SET_SCROLL_TO_INDEX: return setScrollToIndex(state, action);
    case FILTERS_SORTERS_RESET_ALL: return resetAll(state, action);
    default:
      return state;
  }
}

export default FiltersSortersReducer;
