import { updateObject } from '../Utility';
import { COMMENT_FLAG_POST_START, COMMENT_FLAG_POST_SUCCESS,
         COMMENT_FLAG_POST_FAIL } from '../actions/CommentFlagsActions'


const initialState = {
  loading: false,
  error: null,
}

// POST:
const postCommentFlagStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const postCommentFlagSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
  });
}

const postCommentFlagFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


const CommentFlagsReducer = (state=initialState, action) => {
  switch (action.type) {
    // POST:
    case COMMENT_FLAG_POST_START: return postCommentFlagStart(state, action);
    case COMMENT_FLAG_POST_SUCCESS: return postCommentFlagSuccess(state, action);
    case COMMENT_FLAG_POST_FAIL: return postCommentFlagFail(state, action);

    default:
      return state;
  }
}

export default CommentFlagsReducer;
