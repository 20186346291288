// import built-in modules
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Imoprt third-party modules
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";

export default function LandingView(props, context) {
  const history = useHistory();

  const profile = useSelector((state) => state.ProfileState);

  useEffect(() => {
    if (profile.data.type === "IS") {
      return history.push("/console-issara/metrics");
    } else if (profile.data.type === "RA") {
      return history.push("/console-recruiter");
    } else if (profile.data.type == "EM") {
      return history.push("/console-employer");
    } else if (profile.data.type == "JS") {
      return history.push("/not-valid-user/decide-redirection");
    }
  }, [profile.data]);

  return (
    <div style={{ height: "100vh" }}>
      <div className="spinWrapper">
        <Spin tip={context.t("Loading..")} />
      </div>
    </div>
  );
}

LandingView.contextTypes = {
  t: PropTypes.func.isRequired,
};
