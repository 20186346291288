import { updateObject } from '../Utility';
import {
  USERS_GET_START, USERS_GET_SUCCESS, USERS_GET_FAIL, USERS_ADD_NEW,
  USER_AVATAR_UPDATE_START, USER_AVATAR_UPDATE_SUCCESS,USER_AVATAR_UPDATE_FAIL,
  USER_NOTIFICATION_SETTINGS_UPDATE_START, USER_NOTIFICATION_SETTINGS_UPDATE_SUCCESS,
  USER_NOTIFICATION_SETTINGS_UPDATE_FAIL } from '../actions/UsersActions'


const initialState = {
  items: {},
  loading: false,
  updating: false,
  fetched: false,
  error: null,
}

// GET:
const getUsersStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      ...action.users.reduce((obj, item) => {
         obj[item.id] = item
         return obj
       }, {})
    },
    loading: false,
    fetched: true,
    error: null,
  });
}

const getUsersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


// UPDATE:
const updateUserAvatarStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    updating: true,
    error: null,
  });
}

const updateUserAvatarSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.user_id]: {
        ...state.items[action.user_id],
        avatar: action.avatar
      }
    },
    loading: false,
    updating: false,
    error: null,
  });
}

const updateUserAvatarFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    updating: false,
    error: action.error,
  });
}



const addUser = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.user.id]: {
        ...action.user
      }
    },
    loading: false,
    error: null,
  });
}


const UsersReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case USERS_GET_START: return getUsersStart(state, action);
    case USERS_GET_SUCCESS: return getUsersSuccess(state, action);
    case USERS_GET_FAIL: return getUsersFail(state, action);

    // UPDATE:
    case USER_AVATAR_UPDATE_START: return updateUserAvatarStart(state, action);
    case USER_AVATAR_UPDATE_SUCCESS: return updateUserAvatarSuccess(state, action);
    case USER_AVATAR_UPDATE_FAIL: return updateUserAvatarFail(state, action);

   
    // OTHER:
    case USERS_ADD_NEW: return addUser(state, action);

    default:
      return state;
  }
}

export default UsersReducer;
