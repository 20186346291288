import { updateObject } from '../Utility';
import {
  RATINGS_POST_START, RATINGS_POST_SUCCESS, RATINGS_POST_FAIL,
  RATINGS_UPDATE_START, RATINGS_UPDATE_SUCCESS, RATINGS_UPDATE_FAIL,RATING_POST_STATE
} from '../actions/RatingsActions'


const initialState = {
  loading: false,
  error: null,
  state:null
}


// POST:
const postRatingStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const postRatingSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
  });
}
const postRatingstate = (state, action) => {
  return updateObject(state, {
   state : action.state,
  });
}

const postRatingFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

// PATCH:
const updateRatingStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const updateRatingSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
  });
}

const updateRatingFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


const RatingsReducer = (state=initialState, action) => {
  switch (action.type) {
    // POST:
    case RATINGS_POST_START: return postRatingStart(state, action);
    case RATINGS_POST_SUCCESS: return postRatingSuccess(state, action);
    case RATINGS_POST_FAIL: return postRatingFail(state, action);
    case RATING_POST_STATE: return postRatingstate(state, action);

    // PATCH:
    case RATINGS_UPDATE_START: return updateRatingStart(state, action);
    case RATINGS_UPDATE_SUCCESS: return updateRatingSuccess(state, action);
    case RATINGS_UPDATE_FAIL: return updateRatingFail(state, action);

    default:
      return state;
  }
}

export default RatingsReducer;
