import axios from 'axios';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const NATIONALITIES_GET_START = 'NATIONALITIES_GET_START';
export const NATIONALITIES_GET_SUCCESS = 'NATIONALITIES_GET_SUCCESS';
export const NATIONALITIES_GET_FAIL = 'NATIONALITIES_GET_FAIL';


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getNationalitiesStart = () => {
  return {
    type: NATIONALITIES_GET_START,
  }
}

export const getNationalitiesSuccess = (nationalities) => {
  return {
    type: NATIONALITIES_GET_SUCCESS,
    nationalities: nationalities,
  }
}

export const getNationalitiesFail = error => {
  return {
    type: NATIONALITIES_GET_FAIL,
    error: error
  }
}

export const getNationalities = () => {
  return (dispatch, getState) => {
      dispatch(getNationalitiesStart());
      const language = getState().i18nState.lang
      return axios.get(`${process.env.REACT_APP_API_URL}/localization/nationalities?lang=${language}`)
      .then(res => {
          dispatch(getNationalitiesSuccess(res.data));
          return getState().NationalitiesState.data
      })
      .catch(err => {
          dispatch(getNationalitiesFail(err))
          throw err
      })
  }
}
