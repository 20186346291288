import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const NOTIFICATIONS_GET_START = "NOTIFICATIONS_GET_START";
export const NOTIFICATIONS_GET_SUCCESS = "NOTIFICATIONS_GET_SUCCESS";
export const NOTIFICATIONS_GET_FAIL = "NOTIFICATIONS_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getNotificationStart = () => {
  return {
    type: NOTIFICATIONS_GET_START,
  };
};

export const getNotificationsSuccess = (notifications) => {
  return {
    type: NOTIFICATIONS_GET_SUCCESS,
    notifications: notifications,
  };
};

export const getNotificationsFail = (error) => {
  return {
    type: NOTIFICATIONS_GET_FAIL,
    error: error,
  };
};

export const getNotifications = () => {
  return (dispatch, getState) => {
    dispatch(getNotificationStart());
    return axios
      .get(`${process.env.REACT_APP_API_V2_URL}/notifications`)
      .then((res) => {
        dispatch(getNotificationsSuccess(res.data.notifications));
        return getState().NotificationsState.items;
      })
      .catch((err) => {
        dispatch(getNotificationsFail(err));
        throw err;
      });
  };
};
