// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// Imoprt third-party modules
import axios from 'axios';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Result, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

// Import goldendreams modules
import { authBusinessSignup } from '../../common/redux/actions/AuthenticationActions';

// Import styles
import styles from './EmailVerificationView.module.css';


export default function EmailVerificationView(props, context) {
  const dispatch = useDispatch()
  const history = useHistory()

  let { uid64, token} = useParams();
  const [state, setState] = useState({
    loading: false,
    activated: false
  })

  useEffect(() => {
    setState({...state, loading: true})

    axios.post(`${process.env.REACT_APP_API_URL}/users/email-verification`, {
      uid64: uid64,
      token: token
    })
    .then(res => {
      setState({loading: false, activated: true})
    })
    .catch(err => {
      setState({loading: false, activated: false})
    })
  }, [])

  const goToConsole = () => {
    history.push('/')
    window.location.reload()
  }

  const goToLogin = () => {
    history.push('/login')
    window.location.reload()
  }

  return (
    <div className={styles.container}>
      {
        state.loading?
        <Spin tip={context.t('Email verification in progress..')}/>
        :
        state.activated?
        <Result
          status="success"
          title={context.t('Successfully verified email')}
          subTitle={context.t('Please follow further instructions in the console')}
          extra={[
            <Button type="primary" onClick={goToConsole}>
              {context.t('Go to console')}
            </Button>
          ]}
        />
        :
        <Result
          status="warning"
          title={context.t('Email verification failed')}
          subTitle={context.t('This link has already expired.')}
          extra={[
            <Button type="primary" onClick={goToConsole}>
              {context.t('Go to console')}
            </Button>,
            <Button onClick={goToLogin}>
              {context.t('Login')}
            </Button>
          ]}
        />
      }
    </div>
  )
}

EmailVerificationView.contextTypes = {
  t: PropTypes.func.isRequired
}
