import { updateObject } from '../Utility';
import {
  EDUCATION_LEVELS_GET_START, EDUCATION_LEVELS_GET_SUCCESS,
  EDUCATION_LEVELS_GET_FAIL
} from '../actions/EducationLevelsActions'


const initialState = {
  items: {},
  itemsArray: [],
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getEducationLevelsStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getEducationLevelsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.educationLevels.reduce((obj, item) => {
      obj[item.education] = item
      return obj
    }, {}),
    itemsArray: action.educationLevels,
    loading: false,
    fetched: true,
    error: null,
  });
}

const getEducationLevelsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}


const EducationLevelsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case EDUCATION_LEVELS_GET_START: return getEducationLevelsStart(state, action);
    case EDUCATION_LEVELS_GET_SUCCESS: return getEducationLevelsSuccess(state, action);
    case EDUCATION_LEVELS_GET_FAIL: return getEducationLevelsFail(state, action);

    default:
      return state;
  }
}

export default EducationLevelsReducer;
