import { updateObject } from '../Utility';
import {
  RECRUITER_ROSTER_GET_START,
  RECRUITER_ROSTER_GET_SUCCESS,
  RECRUITER_ROSTER_GET_FAIL,

} from '../actions/RecruiterRosterActions'

const initialState = {
  items: {},
  itemsArray: [],
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getRecruiterRosterStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
}

const getRecruiterRosterSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    itemsArray: action.recruiterRoster,
    items: action.recruiterRoster.reduce((obj, item) => {
      obj[item.id] = item
      return obj
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
}

const getRecruiterRosterFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
}



const RecruiterRosterReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECRUITER_ROSTER_GET_START: return getRecruiterRosterStart(state, action);
    case RECRUITER_ROSTER_GET_SUCCESS: return getRecruiterRosterSuccess(state, action);
    case RECRUITER_ROSTER_GET_FAIL: return getRecruiterRosterFail(state, action);



    default:
      return state;

  }
}

export default RecruiterRosterReducer;
