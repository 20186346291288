import { updateObject } from '../Utility';
import { JOB_BENEFITS_GET_START, JOB_BENEFITS_GET_SUCCESS,
         JOB_BENEFITS_GET_FAIL } from '../actions/JobBenefitsActions'


const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getJobBenefitsStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
}

const getJobBenefitsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.jobBenefits.reduce((obj, item) => {
       obj[item.id] = item
       return obj
     }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
}

const getJobBenefitsFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
}


const JobBenefitsReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case JOB_BENEFITS_GET_START: return getJobBenefitsStart(state, action);
    case JOB_BENEFITS_GET_SUCCESS: return getJobBenefitsSuccess(state, action);
    case JOB_BENEFITS_GET_FAIL: return getJobBenefitsFail(state, action);

    default:
      return state;
  }
}

export default JobBenefitsReducer;
