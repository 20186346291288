import axios from 'axios';

// zawgyi-unicode converter
const Rabbit = require("rabbit-node");


// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const EMPLOYERS_GET_START = 'EMPLOYERS_GET_START';
export const EMPLOYERS_GET_SUCCESS = 'EMPLOYERS_GET_SUCCESS';
export const EMPLOYERS_GET_FAIL = 'EMPLOYERS_GET_FAIL';

export const EMPLOYERS_ADD_COMMENT = 'EMPLOYERS_ADD_COMMENT';
export const EMPLOYER_ADD_COMMENT = 'EMPLOYER_ADD_COMMENT';
export const EMPLOYERS_ADD_CHILD_COMMENT = 'EMPLOYERS_ADD_CHILD_COMMENT';
export const EMPLOYER_ADD_CHILD_COMMENT = 'EMPLOYER_ADD_CHILD_COMMENT';
export const EMPLOYERS_RECALCULATE_SCORE = 'EMPLOYERS_RECALCULATE_SCORE';

export const EMPLOYERS_VERIFY_NEW = 'EMPLOYERS_VERIFY_NEW';
export const EMPLOYERS_REJECT_NEW = 'EMPLOYERS_REJECT_NEW';
export const EMPLOYERS_MERGE_NEW = 'EMPLOYERS_MERGE_NEW';

export const PENDING_EMP_FAIL = 'PENDING_EMP_FAIL';
export const PENDING_EMP_START = 'PENDING_EMP_START';
export const PENDING_EMP_SUCCESS = 'PENDING_EMP_SUCCESS';

export const VERIFIED_EMPLOYER_START = 'VERIFIED_EMPLOYER_START';
export const VERIFIED_EMPLOYER_FAIL = 'VERIFIED_EMPLOYER_FAIL';
export const VERIFIED_EMPLOYER_SUCCESS = 'VERIFIED_EMPLOYER_SUCCESS';

export const GET_ADDRESS_START = 'GET_ADDRESS_START';
export const GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';

export const GET_EMP_DETAIL_START = 'GET_EMP_DETAIL_START';
export const GET_EMP_DETAIL_FAIL = 'GET_EMP_DETAIL_FAIL';
export const GET_EMP_DETAIL_SUCCESS = 'GET_EMP_DETAIL_SUCCESS';

export const EMP_COMMENT_REMOVE = 'EMP_COMMENT_REMOVE';


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getEmployersStart = () => {
  return {
    type: EMPLOYERS_GET_START,
  }
}

export const getEmployersSuccess = (employers) => {
  return {
    type: EMPLOYERS_GET_SUCCESS,
    employers: employers,
  }
}

export const getEmployersFail = error => {
  return {
    type: EMPLOYERS_GET_FAIL,
    error: error
  }
}

// GET EMP:
export const getEmployerDetailStart = () => {
  return {
    type: GET_EMP_DETAIL_START,
  }
}

export const getEmployerDetailSuccess = (employer) => {
  return {
    type: GET_EMP_DETAIL_SUCCESS,
    employer: employer,
  }
}

export const getEmployerDetailFail = error => {
  return {
    type: GET_EMP_DETAIL_FAIL,
    error: error
  }
}

// GET EMPLOYER ADDRESS :
export const getEmployerAddressStart = () => {
  return {
    type: GET_ADDRESS_START,
  }
}

export const getEmployerAddressSuccess = (address) => {
  return {
    type: GET_ADDRESS_SUCCESS,
    address: address,
  }
}

export const getEmployerAddressFail = error => {
  return {
    type: GET_ADDRESS_FAIL,
    error: error
  }
}

export const EmpCommentRemove = comment => {
  return {
   type: EMP_COMMENT_REMOVE,
   comment: comment
  }
}

export const getEmployerAddress = (employerId) => {
  return (dispatch, getState) => {
    dispatch(getEmployerAddressStart());

    const language = getState().i18nState.lang

    axios.get(`${process.env.REACT_APP_API_V2_URL}/business/employers/${employerId}/address?lang=${language}`)
      .then(res => {
        dispatch(getEmployerAddressSuccess(res.data));
        // return getState().EmployersState.items
      })
      .catch(err => {
        dispatch(getEmployerAddressFail(err))
        throw err
      })
  }
}

export const getEmployer = (id) => {
  return (dispatch, getState) => {
    dispatch(getEmployerDetailStart());

    const language = getState().i18nState.lang

    axios.get(`${process.env.REACT_APP_API_V2_URL}/employers/${id}?lang=${language}`)
      .then(res => {
        let employer = res.data

        // add unicode names to be used in the search later
        if (language === 'mm-z') {
          employer = { ...employer, name_mm_uni: employer && employer.name && Rabbit.zg2uni(employer.name) }
        }
        dispatch(getEmployerDetailSuccess(employer));
        return getState().EmployersState.detail.item
      })
      .catch(err => {
        dispatch(getEmployerDetailFail(err))
        throw err
      })
  }
}


export const getEmployers = () => {
  return (dispatch, getState) => {
    dispatch(getEmployersStart());

    const language = getState().i18nState.lang

    axios.get(`${process.env.REACT_APP_API_URL}/employers?lang=${language}`)
      .then(res => {
        let employers = res.data

        // add unicode names to be used in the search later
        if (language === 'mm-z') {
          employers = employers.map(employer => {
            return { ...employer, name_mm_uni: employer && employer.name && Rabbit.zg2uni(employer.name) }
          })
        }

        dispatch(getEmployersSuccess(employers));
        return getState().EmployersState.items
      })
      .catch(err => {
        dispatch(getEmployersFail(err))
        throw err
      })
  }
}

export const getExtendedEmployers = () => {
  return (dispatch, getState) => {
    dispatch(getEmployersStart());

    let url = `${process.env.REACT_APP_API_URL}/employers-extended`

    axios.get(url)
      .then(res => {
        dispatch(getEmployersSuccess(res.data));
      })
      .catch(err => {
        dispatch(getEmployersFail(err))
      })
  }
}


// OTHER:
export const addEmployersComment = (comment) => {
  return {
    type: EMPLOYERS_ADD_COMMENT,
    comment: comment,
  }
}

export const addEmployerComment = (comment) => {
  return {
    type: EMPLOYER_ADD_COMMENT,
    comment: comment,
  }
}


export const addEmployersChildComment = (comment) => {
  return {
    type: EMPLOYERS_ADD_CHILD_COMMENT,
    comment: comment,
  }
}

export const addEmployerChildComment = (comment) => {
  return {
    type: EMPLOYER_ADD_CHILD_COMMENT,
    comment: comment,
  }
}

export const recalculateEmployerScore = (
  employer_id, detailed_scores, offsetCount, offsetScore, update = false
) => {
  return {
    type: EMPLOYERS_RECALCULATE_SCORE,
    employer_id: employer_id,
    detailed_scores: detailed_scores,
    offsetCount: offsetCount,
    offsetScore: offsetScore,
    update: update
  }
}

export const verifiyNewEmployer = (id) => {
  return {
    type: EMPLOYERS_VERIFY_NEW,
    id: id
  }
}

export const rejectNewEmployer = (id) => {
  return {
    type: EMPLOYERS_REJECT_NEW,
    id: id
  }
}

export const mergeNewEmployer = (primaryId, secondaryId) => {
  return {
    type: EMPLOYERS_REJECT_NEW,
    primaryId: primaryId,
    secondaryId: secondaryId
  }
}

// get pending employers
export const getPendingEmpSuccess = (emp) => {
  return {
    type: PENDING_EMP_SUCCESS,
    emp: emp
  }
}

export const getPendingEmpFail = error => {
  return {
    type: PENDING_EMP_FAIL,
    error: error
  }
}

export const getPendingEmpStart = () => {
  return {
    type: PENDING_EMP_START
  }
}

// get verified employers 
export const getVerifiedEmpSuccess = (emp) => {
  return {
    type: VERIFIED_EMPLOYER_SUCCESS,
    emp: emp
  }
}

export const getVerifiedEmpFail = error => {
  return {
    type: VERIFIED_EMPLOYER_FAIL,
    error: error
  }
}

export const getVerifiedEmpStart = () => {
  return {
    type: VERIFIED_EMPLOYER_START
  }
}

export const getVerifiedEmp = () => {
  return (dispatch, getState) => {
    dispatch(getVerifiedEmpStart());

    let url = `${process.env.REACT_APP_API_URL}/employers-extended?managed_by=User&email_status=true&user_type=EM`

    axios.get(url)
      .then(res => {
        dispatch(getVerifiedEmpSuccess(res.data));
      })
      .catch(err => {
        dispatch(getVerifiedEmpFail(err))
      })
  }

}

export const getPendingEmp = () => {
  return (dispatch, getState) => {
    dispatch(getPendingEmpStart());

    let url = `${process.env.REACT_APP_API_URL}/employers-extended?managed_by=Pending&email_status=true&user_type=EM`
    axios.get(url)
      .then(res => {
        dispatch(getPendingEmpSuccess(res.data));
      })
      .catch(err => {
        dispatch(getPendingEmpFail(err))
      })
  }

}

export const getPendingEmpByUrl = (url) => {
  return (dispatch, getState) => {
    dispatch(getPendingEmpStart());
    
    axios.get(url)
      .then(res => {
        dispatch(getPendingEmpSuccess(res.data));
      })
      .catch(err => {
        dispatch(getPendingEmpFail(err))
      })
  }
}

export const getVerifiedEmpByUrl = (url) => {
  return (dispatch, getState) => {
    dispatch(getVerifiedEmpStart());
    axios.get(url)
      .then(res => {
        dispatch(getVerifiedEmpSuccess(res.data));
      })
      .catch(err => {
        dispatch(getVerifiedEmpFail(err))
      })
  }
}