import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const CITIES_GET_START = "CITIES_GET_START";
export const CITIES_GET_SUCCESS = "CITIES_GET_SUCCESS";
export const CITIES_GET_FAIL = "CITIES_GET_FAIL";

export const CITY_GET_START = "CITY_GET_START";
export const CITY_GET_SUCCESS = "CITY_GET_SUCCESS";
export const CITY_GET_FAIL = "CITY_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getCitiesStart = () => {
  return {
    type: CITIES_GET_START,
  };
};

export const getCitiesSuccess = (cities) => {
  return {
    type: CITIES_GET_SUCCESS,
    cities: cities,
  };
};

export const getCitiesFail = (error) => {
  return {
    type: CITIES_GET_FAIL,
    error: error,
  };
};

export const getCityStart = () => {
  return {
    type: CITY_GET_START,
  };
};

export const getCitySuccess = (city) => {
  return {
    type: CITY_GET_SUCCESS,
    city: city,
  };
};

export const getCityFail = (error) => {
  return {
    type: CITY_GET_FAIL,
    error: error,
  };
};

export const getCities = () => {
  return (dispatch, getState) => {
    dispatch(getCitiesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/localization/cities?lang=${language}`
      )
      .then((res) => {
        dispatch(getCitiesSuccess(res.data));
        return getState().CitiesState.items;
      })
      .catch((err) => {
        dispatch(getCitiesFail(err));
        throw err;
      });
  };
};

export const getCity = (cityId) => {
  return (dispatch, getState) => {
    dispatch(getCityStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_V2_URL}/localization/cities/${cityId}?lang=${language}`
      )
      .then((res) => {
        dispatch(getCitySuccess(res.data));
        return getState().CitiesState.details.data;
      })
      .catch((err) => {
        dispatch(getCityFail(err));
        throw err;
      });
  };
};
