import { updateObject } from '../Utility';
import {
  PROBLEMS_GET_START, PROBLEMS_GET_SUCCESS, PROBLEMS_GET_FAIL,
  PROBLEMS_POST_MESSAGE_START, PROBLEMS_POST_MESSAGE_SUCCESS,
  PROBLEMS_POST_MESSAGE_FAIL, PROBLEMS_RESOLVE_PROBLEM_START,
  PROBLEMS_RESOLVE_PROBLEM_SUCCESS, PROBLEMS_RESOLVE_PROBLEM_FAIL

} from '../actions/ProblemsActions'


const initialState = {
  items: [],
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getProblemsStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
}

const getProblemsSuccess = (state, action) => {
  return updateObject(state, {
    items: action.problems,
    loading: false,
    fetched: true,
    error: null,
  });
}

const getProblemsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
}

// POST:
const postMessageSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: state.items.map(item => item.id === action.message.problem_id?
      {...item, messages: [...item.messages, action.message], status: 'WAITING_FOR_WORKER', updated_at: new Date()}
      :
      item
    )
  });
}

// PATCH:
const resolveProblemSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: state.items.map(item => item.id === action.problem.id?
      {...item, status: 'RESOLVED'}
      :
      item
    )
  });
}




const ProblemsReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case PROBLEMS_GET_START: return getProblemsStart(state, action);
    case PROBLEMS_GET_SUCCESS: return getProblemsSuccess(state, action);
    case PROBLEMS_GET_FAIL: return getProblemsFail(state, action);

    // POST:
    case PROBLEMS_POST_MESSAGE_SUCCESS: return postMessageSuccess(state, action);

    // PATCH:
    case PROBLEMS_RESOLVE_PROBLEM_SUCCESS: return resolveProblemSuccess(state, action)

    default:
      return state;
  }
}

export default ProblemsReducer;
