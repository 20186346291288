// import built-in modules
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// Imoprt third-party modules
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Result, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

// Import goldendreams modules
import { clearAuthLocalStorage } from '../../../common/redux/actions/AuthenticationActions';

// Import styles
import styles from './NotFoundView.module.css';


export default function NotFoundView(props, context) {
  const history = useHistory()

  return (
    <div className={styles.container}>
      <Result
        status="404"
        title="404"
        subTitle={context.t("Sorry, the page you visited does not exist.")}
        extra={
          <Button type="primary" onClick={() => history.push('/')}>{context.t("Back Home")}</Button>
        }
      />
    </div>
  )
}

NotFoundView.contextTypes = {
  t: PropTypes.func.isRequired
}
