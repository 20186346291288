import axios from 'axios';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_HIDE_SUCCESS = 'COMMENT_HIDE_SUCCESS';

export const deleteCommentSuccess = (id) => {
  return {
    type: COMMENT_DELETE_SUCCESS,
    payload: id,
  }
}
export const hideCommentSuccess = (id, state) => {
  return {
    type: COMMENT_HIDE_SUCCESS,
    payload: id,
    state: state,
  }
}
export const hideComment = (id, state) => {
  return (dispatch) => {
    axios.patch(
      `${process.env.REACT_APP_API_URL}/comments-hide/${id}`,
      { is_hide: state }
    )
      .then((res) => {
        dispatch(hideCommentSuccess(id, state))
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export const deleteComment = (id) => {
  return async (dispatch) => {
    dispatch(deleteCommentSuccess(id));
    axios.delete(
      `${process.env.REACT_APP_API_URL}/comments/${id}`
    )
      .then((res) => {
        console.log(res)
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
