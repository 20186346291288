import { updateObject } from '../Utility';
import { SUB_INDUSTRIES_GET_START, SUB_INDUSTRIES_GET_SUCCESS,
         SUB_INDUSTRIES_GET_FAIL
} from '../actions/SubIndustriesActions'


const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
}

// GET:
const getSubIndustriesStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
}

const getSubIndustriesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.SubIndustries.reduce((obj, item) => {
       obj[item.id] = item
       return obj
     }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
}

const getSubIndustriesFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
}


const SubIndustriesReducer = (state=initialState, action) => {
  switch (action.type) {
    // GET:
    case SUB_INDUSTRIES_GET_START: return getSubIndustriesStart(state, action);
    case SUB_INDUSTRIES_GET_SUCCESS: return getSubIndustriesSuccess(state, action);
    case SUB_INDUSTRIES_GET_FAIL: return getSubIndustriesFail(state, action);

    default:
      return state;
  }
}

export default SubIndustriesReducer;
